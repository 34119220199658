import React from "react";
import { createClient } from "@supabase/supabase-js";
import { Link } from "react-router-dom";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <p>
      <br />
      <Link className="footerLink" to="/">
        Chat
      </Link>
      <Link className="footerLink" to="/story">
        Story
      </Link>
      <Link className="footerLink" to="/review">
        Review
      </Link>
      <Link className="footerLink" to="/realtime">
        Realtime
      </Link>
      <Link className="footerLink" to="/review-en">
        Reverse
      </Link>
      <Link className="footerLink" to="/review-images">
        Images
      </Link>
      <Link className="footerLink" to="/review-sentences">
        Sentences
      </Link>
      {/* <Link className="footerLink" to="/type-sentences">
        Type
      </Link> */}
      <Link className="footerLink" to="/tone-challenge">
        Tones
      </Link>
      <br /> <br />
      <Link className="footerLink" to="/terms">
        Terms
      </Link>
      <Link className="footerLink" to="/privacy">
        Privacy
      </Link>
    </p>
  );
};

export default Footer;
