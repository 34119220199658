import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatScreen from "./ChatScreen";
import HomeScreen from "./HomeScreen";
import PrivacyScreen from "./Privacy";
import TermsScreen from "./Terms";
import UserSettingsScreen from "./UserSettingsScreen";
import GetStarted from "./GetStarted";
import Navbar from "./Navbar";
import ReviewImagesScreen from "./ReviewImagesScreen";
import ReviewSentencesScreen from "./ReviewSentencesScreen";
import TypeSentencesScreen from "./TypeSentences";
import ToneChallengeScreen from "./ToneChallenge";
import ReviewScreenEn from "./ReviewScreenEn";

import "./App.css";
import "./Navbar.css";
import StoryScreen from "./StoryScreen";
import ReviewScreen from "./ReviewScreen";
import RealtimeScreen from "./RealtimeScreen";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/start" element={<GetStarted />} />
          <Route path="/user" element={<UserSettingsScreen />} />
          <Route path="/chat" element={<ChatScreen />} />
          <Route path="/privacy" element={<PrivacyScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
          <Route path="/story" element={<StoryScreen />} />
          <Route path="/review" element={<ReviewScreen />} />
          <Route path="/review-en" element={<ReviewScreenEn />} />
          <Route path="/review-images" element={<ReviewImagesScreen />} />
          <Route path="/review-sentences" element={<ReviewSentencesScreen />} />
          <Route path="/type-sentences" element={<TypeSentencesScreen />} />
          <Route path="/tone-challenge" element={<ToneChallengeScreen />} />
          <Route path="/realtime" element={<RealtimeScreen />} />
        </Routes>
      </div>
    </Router>
  );
};
export default App;
