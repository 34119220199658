// store.ts
import { configureStore, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import chatReducer from "./chatSlice";
import characterReducer from "./characterSlice";
import userReducer from "./userSlice";
import subscriptionReducer from "./subscriptionSlice";

export const store = configureStore({
  reducer: {
    chat: chatReducer,
    character: characterReducer,
    user: userReducer,
    subscription: subscriptionReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;

// Define a type for the thunk actions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  undefined,
  Action<string>
>;

export type AppDispatch = typeof store.dispatch;
