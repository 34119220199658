import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { act } from "react-dom/test-utils";

interface UserState {
  userTokens: number;
  userCheckoutURL: string;
  userCheckoutURLExpiry: number;
  stripeCustomerID: string;
  stripeManageURL: string;
  navModalOpen: boolean;
}

// Default user state
const initialState: UserState = {
  userTokens: 0,
  userCheckoutURL: "",
  userCheckoutURLExpiry: 0,
  stripeCustomerID: "",
  stripeManageURL: "",
  navModalOpen: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserState: (state, action: PayloadAction<UserState>) => {
      return action.payload;
    },
    setUserTokens: (state, action: PayloadAction<number>) => {
      state.userTokens = action.payload;
    },
    setUserCheckoutURL: (state, action: PayloadAction<string>) => {
      state.userCheckoutURL = action.payload;
    },
    setUserCheckoutURLExpiry: (state, action: PayloadAction<number>) => {
      state.userCheckoutURLExpiry = action.payload;
    },
    setStripeCustomerID: (state, action: PayloadAction<string>) => {
      state.stripeCustomerID = action.payload;
    },
    setStripeManageURL: (state, action: PayloadAction<string>) => {
      state.stripeManageURL = action.payload;
    },
    toggleNavModalOpen: state => {
      state.navModalOpen = !state.navModalOpen;
      console.log(state.navModalOpen);
    }
  }
});

export const {
  setUserState,
  setUserTokens,
  setUserCheckoutURL,
  setUserCheckoutURLExpiry,
  setStripeCustomerID,
  setStripeManageURL,
  toggleNavModalOpen
} = userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export const selectUserTokens = (state: RootState) => state.user.userTokens;

export const selectUserCheckoutURL = (state: RootState) =>
  state.user.userCheckoutURL;

export const selectStripeManageURL = (state: RootState) =>
  state.user.stripeManageURL;

export const selectUserCheckoutURLExpiry = (state: RootState) =>
  state.user.userCheckoutURLExpiry;

export const selectStripeCustomerID = (state: RootState) =>
  state.user.stripeCustomerID;

export const selectNavModalOpen = (state: RootState) => state.user.navModalOpen;

export default userSlice.reducer;
