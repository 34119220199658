import React, { useState, useEffect, CSSProperties, useCallback } from "react";
import { Link } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
import { FaEye } from "react-icons/fa";
import WordModal from "./components/WordModal";
import { LexiconResult } from "./components/Constants";
import Footer from "./components/Footer";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

const clearTextSelection = () => {
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }
};

interface WordExample {
  word: string;
  example: string;
  example_en: string;
  created: string;
  image: string;
}

const ReviewSentencesScreen: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [wordExamples, setWordExamples] = useState<WordExample[]>([]); 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showTranslation, setShowTranslation] = useState(false);
  const [segmentedExample, setSegmentedExample] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [modalText, setModalText] = useState<string>("");
  const [modalTranslationText, setModalTranslationText] = useState<string>("");
  const [modalIsBookmarked, setModalIsBookmarked] = useState<boolean>(false);
  const [modalLex, setModalLex] = useState<LexiconResult[]>([]);
  const [bookmarks, setBookmarks] = useState<string[]>([]);

  const [inputValue, setInputValue] = useState('');
  const [testResult, setTestResult] = useState<{ pass: boolean; score: number; text: string } | null>(null);

  const segmentText = async (text: string) => {
    const response = await supabase.functions.invoke("heroku-segmenter", {
      body: JSON.stringify({
        query: text
          .replaceAll("  ", " ")
          .replaceAll(" ", " S ")
          .replaceAll("\n\n", " P ")
      })
    });
    const rawWordResp = JSON.parse(response.data);
    let wordToks: string[] = [];

    for (let i = 0; i < rawWordResp.wordTokens.length; i++) {
      if (
        rawWordResp.wordTokens[i] === "\n" ||
        rawWordResp.wordTokens[i] === "PS"
      ) {
        continue;
      } else if (rawWordResp.wordTokens[i] === "P") {
        wordToks.push("\n\n");
      } else if (rawWordResp.wordTokens[i] === "S") {
        wordToks.push(" ");
      } else {
        wordToks.push(rawWordResp.wordTokens[i]);
      }
    }
    setSegmentedExample(wordToks);
  };

  useEffect(() => {
    if (wordExamples[currentIndex]) {
      segmentText(wordExamples[currentIndex].example);
    }
  }, [currentIndex, wordExamples]);

  const handleRepositionModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();

    // Get the scroll positions.
    const scrollLeft = window.scrollX || window.pageXOffset;
    const scrollTop = window.scrollY || window.pageYOffset;

    // Adjust the modal's position based on scroll positions.
    setModalPosition({
      top: rect.top + scrollTop - 100,
      left: rect.left + scrollLeft + rect.width / 2 - 60
    });
  };

  const handleTokenClick = async (
    token: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setModalLex([]);
    setModalTranslationText(".");
    setModalText(token);

    handleRepositionModal(event);

    clearTextSelection();
    setModalIsBookmarked(bookmarks.includes(token));
    setShowModal(true);

    const response = await supabase.functions.invoke("google-translate", {
      body: JSON.stringify({
        query: token
      })
    });

    let { data: lexitron } = await supabase
      .from("lexitron_th_en")
      .select("*")
      .eq("thai_search", token);
    if (lexitron !== null) {
      console.log(lexitron);
      //setModalLexEng = lexitron[0].
      if (lexitron.length > 0) {
        setModalLex(lexitron as LexiconResult[]);
        if (lexitron[0].syllables === null) {
          console.log("null syllables");
          const syllsResp = await supabase.functions.invoke(
            "pythainlp-fetch-syllables",
            {
              body: JSON.stringify({
                word: token
              })
            }
          );
          const sylls = JSON.parse(syllsResp.data);
          console.log(sylls);
        } else {
          console.log(lexitron[0].syllables);
        }
      } else {
        setModalLex([]);
      }
    }

    const resp = JSON.parse(response.data);
    console.log(resp.translatedText);
    setModalTranslationText(resp.translatedText);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const response = await supabase.functions.invoke("openai-comprehension-test", {
        body: JSON.stringify({
          word: wordExamples[currentIndex].word,
          language: "Thai",
          definitions: "",
          example: wordExamples[currentIndex].example,
          example_en: wordExamples[currentIndex].example_en,
          user_translation: inputValue,
        })
      });

      const testResultData = JSON.parse(response.data)
      console.log(testResultData)

      const data = {
        pass: testResultData.pass,
        score: testResultData.score,
        text: testResultData.feedback
      };

      setTestResult(data);
    } catch (error) {
      console.error('Error calling openai-comprehension-test:', error);
    }
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  useEffect(
    () => {
      supabase
        .from("word_examples")
        .select("word, example, example_en, created, image")
        .order("created", { ascending: false })
        .limit(250)
        .then(({ data, error }) => {
          if (error) {
            console.error("Error fetching word examples:", error);
          } else {  
            setWordExamples(data);
          }
        });
    },
    [session]
  );

  const handleNext = () => {
    const randomIndex = Math.floor(Math.random() * wordExamples.length);
    setCurrentIndex(randomIndex);
    setShowTranslation(false);
    setTestResult(null)
    setInputValue('')
  };


  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const fetchBookmarks = useCallback(
    async () => {
      if (session) {
        const { user } = session;
        let { data: user_word_data } = await supabase
          .from("user_word_data")
          .select("word, easiness_factor, last_review_date")
          .eq("user_id", user.id)
          .eq("bookmarked", true);
        if (user_word_data !== null) {
          const words = user_word_data.map(obj => obj.word);
          setBookmarks(words);
        }
      }
    },
    [session]
  );

  useEffect(
    () => {
      fetchBookmarks();
    },
    [session, fetchBookmarks]
  );

  return (
    <div className="AppContainer" style={styles.appContainer}>
      {wordExamples.length > 0 &&
        <>
             <button
                //className="randomButton"
                onClick={handleNext}
                style={{ ...styles.navigationButton }}
              >
                &gt;
              </button>
          <div className="wordExampleText" style={styles.wordExampleText}>
            {segmentedExample.map((token, idx) => {
              const isPunctuation = /[,.<>?()! ]/.test(token);
              const isParagraphSeparator = token === "\n\n";

              const isBookmarked = bookmarks.includes(token);

              if (isParagraphSeparator) {
                return <div key={idx}>&nbsp;</div>;
              }

              return (
                <span
                  key={idx}
                  className={`token ${isPunctuation ? "punctuation" : ""}`}
                  style={{
                    cursor: isPunctuation ? "default" : "pointer",
                    backgroundColor: isBookmarked || token === " " ? "transparent" : "lightyellow",
                  }}
                  onClick={e => {
                    if (!isPunctuation) {
                      handleTokenClick(token, e);
                    }
                  }}
                >
                  {token}
                </span>
              );
            })}
          </div>
          {showTranslation &&
            <p
              className="wordExampleTranslation"
              style={styles.wordExampleTranslation}
            >
              {wordExamples[currentIndex].example_en}
            </p>}
          <button
            className="revealButton"
            onClick={toggleTranslation}
            style={styles.revealButton}
          >
            <FaEye />
          </button>
        </>}

        <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Your translation"
          style={styles.input}
        />
        <button type="submit" style={styles.submitButton}>
          Submit
        </button>
      </form>

      {/* Result display */}
      {testResult && (
        <div style={styles.resultContainer}>
          <p>{testResult.pass ? 'Pass' : 'Fail'}</p>
          <p>Score: {testResult.score} / 10</p>
          <div style={styles.resultText}>{testResult.text}</div>
        </div>
      )}

      <br />
      <Footer />
      {showModal &&
        <WordModal
          text={modalText}
          translationText={modalTranslationText}
          position={modalPosition}
          onClose={() => setShowModal(false)}
          session={session}
          fetchBookmarks={fetchBookmarks} 
          isBookmarked={modalIsBookmarked}
          lexicon={modalLex}
        />}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  appContainer: {
    padding: "20px"
  },
  wordExampleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    position: "relative" // Add position relative to position the navigation buttons
  },
  navigationButton: {
    background: "#FFF",
    border: "1px solid #DDD",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
   
    fontSize: "24px",
    color: "#333",
    cursor: "pointer",
    zIndex: 2 // Ensure the buttons are above the image
  },
  prevButton: {
    left: "10px" // Position the previous button inside the left border of the image
  },
  nextButton: {
    right: "10px" // Position the next button inside the right border of the image
  },
  wordExample: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "100%" // Prevent the text from pushing the buttons out
  },
  wordExampleImage: {
    maxWidth: "100%",
    maxHeight: "500px",
    objectFit: "contain",
    borderRadius: "10px",
    position: "relative" // Add position relative to allow absolute positioning of buttons
  },
  wordExampleText: {
    fontSize: "24px",
    color: "#333",
    marginTop: "20px",
    wordWrap: "break-word", // Ensure the text wraps and doesn't overflow
    textAlign: "center", // Center the text below the image
    backgroundColor: "#FFF", // Give the div a white background
    border: "1px solid #DDD", // Add a light grey border
    borderRadius: "5px", // Rounded corners for the border
    padding: "10px" // Add some padding inside the div
  },
  wordExampleTranslation: {
    fontSize: "20px",
    color: "#666",
    marginTop: "10px",
    textAlign: "center" // Center the translation text
  },
  revealButton: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    position: "relative", // Position the button relatively below the translation
    marginTop: "10px", // Add some space above the button
    backgroundColor: "#f0f0f0",
    border: "none",
    borderRadius: "20px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "20px"
  },
  input: {
    width: "100%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ddd",
    fontSize: "16px"
  },
  submitButton: {
    padding: "10px 20px",
    fontSize: "16px",
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer"
  },
  resultContainer: {
    marginTop: "20px",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#f8f8f8",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center"
  },
  resultText: {
    marginTop: "10px",
    fontSize: "16px",
    color: "#333"
  }
};

export default ReviewSentencesScreen;