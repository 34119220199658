import React, { useCallback } from "react";
import { createClient } from "@supabase/supabase-js";
import { LexiconResult } from "./Constants";
import {
  FaBookmark,
  FaCheck,
  FaCross,
  FaEyeSlash,
  FaRegBookmark,
  FaTimes,
  FaVolumeUp
} from "react-icons/fa"; // Import Font Awesome icons

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

interface WordModalProps {
  text: string;
  session: any;
  translationText: string;
  position: { top: number; left: number };
  onClose: () => void;
  fetchBookmarks: () => void;
  isBookmarked: boolean;
  lexicon: LexiconResult[];
}

const WordModal: React.FC<WordModalProps> = ({
  text,
  session,
  translationText,
  position,
  onClose,
  fetchBookmarks,
  isBookmarked,
  lexicon
}) => {
  const handleBookmark = async () => {
    // Handle bookmark logic here
    const { user } = session;
    if (isBookmarked) {
      await supabase
        .from("user_word_data")
        .update({
          bookmarked: false
        })
        .eq("user_id", user.id)
        .eq("word", text)
        .select();
    } else {
      await supabase.from("user_word_data").upsert({
        word: text,
        en: translationText,
        user_id: user.id,
        bookmarked: true
      });
    }
    fetchBookmarks();
  };

  const handleKnown = () => {
    // Handle user knows the word logic here
  };

  const handleUnknown = () => {
    // Handle user doesn't know the word logic here
  };

  const endPlayback = () => {
    console.log("end playback");
    onClose();
  };

  function handleSpeak(event: React.MouseEvent<SVGElement>) {
    event.stopPropagation();
    if (window.responsiveVoice) {
      window.responsiveVoice.speak(text, "Thai Female", {
        rate: 0.75,
        onend: endPlayback
      });
    } else {
      console.error("ResponsiveVoice not loaded");
    }
  }

  const handleIgnoreClick = async () => {
    // Handle bookmark logic here
    if (window.confirm("Are you sure you want to ignore this word?")) {
      const { user } = session;

      await supabase.from("user_word_data").upsert({
        word: text,
        en: translationText,
        user_id: user.id,
        bookmarked: true,
        ignored: true,
        fully_learned: true,
        fully_learned_reverse: true
      });
      fetchBookmarks();
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: position.top,
        left: position.left,
        background: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 0px 15px rgba(0,0,0,0.6)",
        padding: "10px",
        zIndex: 2, // To ensure modal is above other elements
        textAlign: "center"
      }}
      onClick={onClose}
    >
      {text}
      <div
        style={{
          fontSize: "10px"
        }}
      >
        {lexicon.length > 0
          ? "(" +
            lexicon[0].thai_category.toLowerCase() +
            ") " +
            lexicon[0].eng_entry
          : translationText}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px" // space between the text and the buttons
        }}
      >
        <FaVolumeUp
          onClick={handleSpeak}
          style={{ margin: "0 5px", cursor: "pointer" }}
        />
        {isBookmarked
          ? <FaBookmark
              onClick={handleBookmark}
              style={{ margin: "0 5px", cursor: "pointer" }}
            />
          : <FaRegBookmark
              onClick={handleBookmark}
              style={{ margin: "0 5px", cursor: "pointer" }}
            />}

        <FaEyeSlash
          color="red"
          onClick={handleIgnoreClick}
          style={{ margin: "0 5px", cursor: "pointer" }}
        />
        <FaCheck
          color="green"
          onClick={handleKnown}
          style={{ margin: "0 5px", cursor: "pointer" }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "0",
          height: "0",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: "10px solid #fff"
        }}
      />
    </div>
  );
};

export default WordModal;
