import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import Footer from "./components/Footer";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

const shuffle = <T extends any>(array: T[]): T[] => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
};

const ToneChallengeScreen: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [words, setWords] = useState<any[]>([]);
  const [selectedTones, setSelectedTones] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const [buttonStyles, setButtonStyles] = useState<{ [key: string]: React.CSSProperties }>({});
  const [disabledButtons, setDisabledButtons] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  useEffect(
    () => {
      const fetchWords = async () => {
        const { data, error } = await supabase
          .from("lexitron_th_en")
          .select("*")
          .not("syllables", "is", null);
        if (error) {
          console.error("Error fetching words:", error);
        } else {
          const shuffledWords = shuffle(data);
          setWords(shuffledWords);
        }
      };
      if (session) {
        fetchWords();
      }
    },
    [session]
  );

  useEffect(() => {
    if (selectedTones.length === words[currentWordIndex]?.syllables.toneResult.length) {
      const isCurrentCorrect = words[currentWordIndex].syllables.toneResult.every(
        (syl: any[], index: number) => syl[1] === selectedTones[index]
      );
      setIsCorrect(isCurrentCorrect);
      if (isCurrentCorrect) {
        setTimeout(() => {
          setCurrentWordIndex((prevIndex) => prevIndex + 1);
          setSelectedTones([]);
          setIsCorrect(null);
          setButtonStyles({});
          setDisabledButtons({});
        }, 1000);
      }
    }
  }, [selectedTones, words, currentWordIndex]);

  const handleToneSelection = (syllableIndex: number, tone: string) => {
    console.log(words[currentWordIndex].syllables)
    setSelectedTones(prev => {
      const newTones = [...prev];
      newTones[syllableIndex] = tone;
      return newTones;
    });
    const correctTone = words[currentWordIndex].syllables.toneResult[syllableIndex][1];
    const buttonKey = `${syllableIndex}-${tone}`;
    setButtonStyles(prevStyles => {
      const newStyles = { ...prevStyles };
      if (tone === correctTone) {
        // Disable all buttons for this syllable and set the correct one to green
        ["h", "f", "m", "r", "l"].forEach(t => {
          const key = `${syllableIndex}-${t}`;
          newStyles[key] = {
            ...getButtonStyle(t, syllableIndex),
            backgroundColor: t === tone ? "green" : undefined
          };
          setDisabledButtons(prevDisabled => ({
            ...prevDisabled,
            [key]: true
          }));
        });
      } else {
        newStyles[buttonKey] = {
          ...getButtonStyle(tone, syllableIndex),
          backgroundColor: "red"
        };
      }
      return newStyles;
    });
    if (tone !== correctTone) {
      setTimeout(() => {
        setButtonStyles(prevStyles => ({
          ...prevStyles,
          [buttonKey]: {
            ...getButtonStyle(tone, syllableIndex)
          }
        }));
      }, 500);
    }
  };

  const getButtonStyle = (tone: string, index: number) => {
    const baseStyle = {
      width: '50px',
      height: '50px',
      margin: '2px',
      fontSize: '1em'
    };
    const isSelectedCorrect = selectedTones[index] === words[currentWordIndex].syllables.toneResult[index][1];
    return {
      ...baseStyle,
      backgroundColor: isSelectedCorrect ? "green" : undefined
    };
  };

  return (
    <div className="AppContainer" tabIndex={0} style={{ fontSize: "1.5em" }}>
      {words.length > 0 && (
        <>
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <span style={{ fontSize: "2em" }}>{words[currentWordIndex].thai_search}</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
              alignItems: "start"
            }}
          >
            {words[currentWordIndex].syllables.toneResult.map((syl: any[], index: number) => (
              <div key={index} style={{ textAlign: "center" }}>
                <div style={{ fontSize: "1.2em" }}>
                  {syl[0]}
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {["/", "^", "-", "v", "\\"].map((char, idx) => {
                    const tones = ["H", "F", "M", "R", "L"];
                    const buttonKey = `${index}-${tones[idx].toLowerCase()}`;
                    return (
                      <button
                        key={tones[idx]}
                        onClick={() => handleToneSelection(index, tones[idx].toLowerCase())}
                        style={buttonStyles[buttonKey] || getButtonStyle(tones[idx].toLowerCase(), index)}
                        disabled={disabledButtons[buttonKey]}
                      >
                        {char}
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {isCorrect !== null && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {isCorrect ? "Correct!" : "Try again!"}
        </div>
      )}
      <Footer />
    </div>
    
  );
};

export default ToneChallengeScreen;
