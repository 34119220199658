import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logo from "./images/supalingosmallwhite.png";
import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import moment from "moment"

import { 
  setUserTokens, 
  selectUserTokens,
  selectUserCheckoutURL,
  selectUserCheckoutURLExpiry,
  setUserCheckoutURL,
  setUserCheckoutURLExpiry,
  setStripeCustomerID,
  selectStripeCustomerID,
  setStripeManageURL,
  selectStripeManageURL,
  selectNavModalOpen,
  toggleNavModalOpen
} from "./userSlice";
import { resetSubscription, selectSubscription, setSubscription, SubscriptionState } from "./subscriptionSlice";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);

const Navbar: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [userName, setUserName] = useState("Anonymous")
  //const [isModalOpen, setModalOpen] = useState(false); // state to manage modal visibility

  const isModalOpen = useSelector(selectNavModalOpen)

  const dispatch = useDispatch();
  const userTokens = useSelector(selectUserTokens)

  const userCheckoutURL = useSelector(selectUserCheckoutURL)
  const userCheckoutURLExpiry = useSelector(selectUserCheckoutURLExpiry)

  const stripeManageURL = useSelector(selectStripeManageURL)

  const stripeCustomerID = useSelector(selectStripeCustomerID)

  const subscription = useSelector(selectSubscription)

  useEffect(() => {
    var unixTimestamp = Math.floor(Date.now() / 1000);
    if (((userCheckoutURLExpiry >0 && userCheckoutURLExpiry < unixTimestamp) || userCheckoutURLExpiry) === 0 && !!session) {
      //get new checkout URL
      const getNewCheckout = async () => {
        const response = await supabase.functions.invoke("stripe-checkout-session", undefined);
        const resp = JSON.parse(response.data)
        dispatch(setUserCheckoutURL(resp?.url));
        dispatch(setUserCheckoutURLExpiry(resp?.expiry))
      }
      getNewCheckout();
    }
  }, [userCheckoutURLExpiry, session]);

  // get subscription data
  useEffect(() => {
    if (!!session && stripeCustomerID !== '' && !!stripeCustomerID ){
      const { user } = session;
      const getSub = async () => {
        let { data, error } = await supabase
              .from("user_subscriptions")
              .select()
              .eq("user_id", user.id)
              .order('created', { ascending: false })
              .limit(1)
              .single();
            if (error) {
              console.warn(error);
            } else if (data) {
              console.log(data) 
              const sub: SubscriptionState  = {
                subID: data.id,
                stripeCustomer: data.stripe_customer,
                created: data.created,
                periodStart: data.period_start,
                periodEnd: data.period_end,
                priceID: data.price_id,
                interval: data.interval,
                amount: data.amount,
                currency: data.currency,
                isCancelled: data.is_cancelled,
                cancelledAt: data.cancelled_at,
                expiresAt: data.expires_at,
                status: data.status,
              }
              dispatch(setSubscription(sub));
              // get a link
              const response = await supabase.functions.invoke("stripe-portal-session", {
                body: JSON.stringify({  customer: data.stripe_customer })
              });
              const jdat = JSON.parse(response.data);
              dispatch(setStripeManageURL(jdat.url));
            }
      }
      getSub();
    }
    if (!session){
      dispatch(resetSubscription())
    }
  }, [stripeCustomerID, session]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const closeModal = (event: any) => {
      if (event.target.className !== "modal" && event.target.className !== "modalContent" && isModalOpen) {
        dispatch(toggleNavModalOpen());
      }
    };

    document.addEventListener("click", closeModal);
    return () => document.removeEventListener("click", closeModal);
  }, [isModalOpen]);

  useEffect(
    () => {
      async function getUserSettings() {
        if (!!session) {
          setLoading(true);
          const { user } = session;
          setUid(user.id);
          setProfileImageUrl(user?.user_metadata?.avatar_url)
          setUserName(user?.user_metadata?.name)
          setLoading(false);
        } else {
         setProfileImageUrl('')
          setUserName("Anonymous")
        }
      }
      getUserSettings();

      async function getUserTokens() {
        if (!!session) {
          const { user } = session;
          let { data, error } = await supabase
            .from("user_tokens")
            .select(`tokens`)
            .eq("user_id", user.id)
            .single();
          if (error) {
            console.warn(error);
          } else if (data) {
            dispatch(setUserTokens(data.tokens));
          }
        }
      }
      getUserTokens();

      async function getUserStripeCustomerID() {
        if (!!session) {
          const { user } = session;
          let { data, error } = await supabase
            .from("user_settings")
            .select(`stripe_customer_id`)
            .eq("user_id", user.id)
            .single();
          if (error) {
            console.warn(error);
          } else if (data) {
            console.log('CUSTOMER: ' + data.stripe_customer_id)
            dispatch(setStripeCustomerID(data.stripe_customer_id));
          }
        }
      }
      getUserStripeCustomerID();

    },
    [session]
  );

  useEffect(() => {
    const channel = supabase.channel('tokens_changes') 
    .on('postgres_changes', {
      event: 'UPDATE',
      schema: 'public',
      table: 'user_tokens',
      // filters for just this user's tokens
      filter: 'user_id=eq.'+ uid
    }, (payload) => {
      let toks  = payload?.new["tokens"]
      console.log('tokens updated: '  + toks)
      dispatch(setUserTokens(toks));
    }).subscribe()
    return () => {
      channel.unsubscribe();
    }
  }, [uid])

  const formatCentsToDollars = (cents: number) => {
  let dollars = cents / 100;
  return '$' + dollars.toFixed(2);
}

  return (
    <header>
      <nav className="navbar">
        <div className="navbarLogo">
          <img
            src={logo}
            className="navbarLogoImage"
            alt="supalingo.ai - learn languages by chatting with AI bots."
          />
        </div>
        <div 
          className="userInfo" 
          onClick={(event) => {
            event.stopPropagation();
            dispatch(toggleNavModalOpen())
            //setModalOpen(true);
            console.log(subscription)
          }}
          style={{ cursor: "pointer" }}
        >
          {!!session && (
              <>
              <span className="userName">{userTokens} supatokens -</span>
              <span className="userName">{userName}</span>
              <img className="userIcon" src={profileImageUrl} alt="Profile Icon" />
              </>
          )}
          {!session && (
              <>
              <button className="checkout-button" onClick={() => {}}>
                Sign In / Register
              </button>
              </>
          )}
        </div>
      </nav>
      {isModalOpen && (
        <div 
          className="modal" 
          onClick={() => dispatch(toggleNavModalOpen())} // close the modal when clicking outside
        >
          <div 
            className="modalContent"
            onClick={event => event.stopPropagation()} // prevent clicks on the modal content from closing the modal
          >
            {subscription.subID !=='' && (
            <>
              <div className="modalRow heading">
                <h3>Subscription Details</h3>
              </div>
              <div className="modalRow kvp">
                <span className="kvpkey">Status</span>
                <span className="kvpvalue">{subscription.isCancelled ? "cancelled" : subscription.status}</span>
              </div>
              {subscription.isCancelled && (
                 <div className="modalRow kvp">
                 <span className="kvpkey">Expiry date</span>
                 <span className="kvpvalue">{moment.unix(subscription.periodEnd).format("MMM Do YYYY")}</span>
               </div>
              )}
              {!subscription.isCancelled && (
                <>
                <div className="modalRow kvp">
                <span className="kvpkey">Next token reset</span>
                <span className="kvpvalue">{moment.unix(subscription.periodEnd).format("MMM Do")}</span>
              </div>
              <div className="modalRow kvp">
                <span className="kvpkey">Amount</span>
                <span className="kvpvalue">{subscription.currency} {formatCentsToDollars(subscription.amount)}/{subscription.interval}</span>
              </div>
              <div className="modalRow kvp">
                <span className="kvpkey">Tokens</span>
                <span className="kvpvalue">2,000,000/{subscription.interval}</span>
              </div>
              </>
              )}
            </>
            )}
            

            {uid === '04124eda-af07-4391-a06d-560271db7988' && userCheckoutURL !== '' && !!session && (subscription.subID === '' || subscription.status ==='canceled') && (
                <a href={userCheckoutURL} target="_blank">
                <button className="blue-button nav">
                  Subscribe - USD$5/Month
                </button>
                </a>
            )}

            {uid === '04124eda-af07-4391-a06d-560271db7988' && stripeManageURL !== '' && !!session && (subscription.subID !== '') && (
                <a href={stripeManageURL} target="_blank">
                <button className="blue-button nav">
                  Manage Subscription
                </button>
                </a>
            )}

            {!!session && (
            <>
              <Link className="chatlink" to="/">
              <button className="blue-button nav" onClick={() => {}}>
                Chat
              </button>
              </Link>

              <Link className="chatlink" to="/story">
              <button className="blue-button nav" onClick={() => {}}>
                Story
              </button>
              </Link>

              <Link className="chatlink" to="/review">
              <button className="blue-button nav" onClick={() => {}}>
                Review
              </button>
              </Link>

              <button className="blue-button nav" onClick={() => {
                dispatch(toggleNavModalOpen())
                supabase.auth.signOut();
              }}>
                Sign Out
            </button>
            </>
            )} 

            {!session && (
            <div>
              <Auth
                supabaseClient={supabase}
                appearance={{ theme: ThemeSupa }}
                providers={["google", "apple", "facebook"]}
                onlyThirdPartyProviders={true}
                redirectTo={process.env.REACT_APP_SUPA_AUTH_REDIRECT}
              />
            </div>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

export default Navbar;
