import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { createClient } from "@supabase/supabase-js";
import Footer from "./components/Footer";
// import { Auth } from "@supabase/auth-ui-react";
// import { ThemeSupa } from "@supabase/auth-ui-shared";
import logo from "./images/supalingo.png";
//import { characterData } from "./characterData"; // import the character data
import { FaCog, FaArrowLeft } from "react-icons/fa";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);

const languages = [
  "English",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Chinese",
  "Japanese",
  "Korean",
  "Portuguese",
  "Thai"
];

const UserSettingsScreen: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [chatName, setChatName] = useState<any>(null);
  const [gender, setGender] = useState<any>("Male");
  const [nativeLanguage, setNativeLanguage] = useState<any>(null);
  const [learningLanguage, setLearningLanguage] = useState<any>(null);
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  const setDefaults = () => {
    setChatName("Lingo");
    setGender("Male");
    setNativeLanguage("English");
    setLearningLanguage("Spanish");
    setChangesMade(true);
  };

  useEffect(
    () => {
      async function getUserSettings() {
        if (!!session) {
          setLoading(true);
          const { user } = session;
          let { data, error } = await supabase
            .from("user_settings")
            .select(`chat_name, gender, native_language, learning_language`)
            .eq("user_id", user.id)
            .single();

          if (error) {
            console.warn(error);
            setDefaults();
          } else if (data) {
            setChatName(data.chat_name);
            setGender(data.gender);
            setNativeLanguage(data.native_language);
            setLearningLanguage(data.learning_language);
          }
          setLoading(false);
        }
      }
      getUserSettings();
    },
    [session]
  );

  const signout = () => {
    supabase.auth.signOut();
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { id, value } = e.target;

    switch (id) {
      case "userLanguage":
        setNativeLanguage(value);
        if (value !== "English") {
          setLearningLanguage("English");
        }
        break;
      case "userLearningLanguage":
        setLearningLanguage(value);
        break;
      case "userName":
        setChatName(value);
        break;
      case "userGender":
        setGender(value);
        break;
      default:
        console.warn("Unknown field: " + id);
        break;
    }
    setChangesMade(true);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const { user } = session;
    let { error } = await supabase.from("user_settings").upsert({
      chat_name: chatName,
      gender: gender,
      native_language: nativeLanguage,
      learning_language: learningLanguage,
      user_id: user.id
    });

    if (error !== null) {
      console.warn(error);
    }
    setLoading(false);
    setChangesMade(false);
  };

  return (
    <div className="AppContainer">
      <img src={logo} className="logoImage" alt="supalingo.ai" />
      <br />
      <div className="form-container">
        <div className="homeHeaderContainer">
          <h2>User Settings</h2>
          {!!session &&
            <Link to={"/"}>
              <FaArrowLeft className="homeSettingsIcon" />
            </Link>}
        </div>
        <br />
        <div className="form-field">
          <label htmlFor="userLanguage">Native Language:</label>
          <select
            id="userLanguage"
            value={nativeLanguage || ""}
            onChange={handleChange}
          >
            {languages.map((lang, index) =>
              <option key={index} value={lang}>
                {lang}
              </option>
            )}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="userLearningLanguage">Learning Language:</label>
          {nativeLanguage === "English"
            ? <select
                id="userLearningLanguage"
                value={learningLanguage || ""}
                onChange={handleChange}
              >
                {languages.map((lang, index) =>
                  <option key={index} value={lang}>
                    {lang}
                  </option>
                )}
              </select>
            : <p className="settingsEnglish">English</p>}
        </div>
        <div className="form-field">
          <label htmlFor="userName">First Name (For Chats):</label>
          <input
            type="text"
            id="userName"
            value={chatName || ""}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="userGender">My Gender:</label>
          <select id="userGender" value={gender || ""} onChange={handleChange}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        {changesMade &&
          <button
            className="blue-button"
            disabled={loading}
            onClick={handleUpdate}
          >
            Update
          </button>}
      </div>
      <Footer />
    </div>
  );
};

export default UserSettingsScreen;
