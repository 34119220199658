import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { 
  addMessage, 
  fetchResponse, 
  setMessages, 
  setMessageTranslation, 
  resetHasNewTranslation, 
  setOptions,
} from './chatSlice';
import {selectCharacter} from './characterSlice'
import {Translation} from './chatSlice'
import { RootState, AppDispatch } from './store';
import { v4 as uuidv4 } from 'uuid'; 
import { createClient } from "@supabase/supabase-js";
import { useNavigate } from "react-router-dom";
import { FaCog, FaTimes } from "react-icons/fa";
import Switch from "react-switch";
import logo from "./images/supalingosmallwhite.png";
import { selectUserTokens, toggleNavModalOpen } from './userSlice';

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);


interface ChatScreenProps {}

const ChatScreen: React.FC<ChatScreenProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const messages = useSelector((state: RootState) => state.chat.messages);
  const options = useSelector((state: RootState)=> state.chat.options)
  const [loading, setLoading] = useState(true);
  const isCharacterTyping = useSelector((state: RootState)=> state.chat.isCharacterTyping)
  const [input, setInput] = useState<string>("");
  const [chatId, setChatId] = useState<string>("")
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const character = useSelector(selectCharacter);

  // for chat suggestions
  const [showInLearningLanguage, setShowInLearningLanguage] = useState(true);

  const [showSettings, setShowSettings] = useState(false);

  const [userName,setUserName] = useState("")
  const [userLang,setUserLang] = useState("")
  const [userLearningLang,setUserLearningLang] = useState("")
  const [userGender,setUserGender] = useState("")

  const userTokens = useSelector(selectUserTokens)

  //what is the translated language?
  const txLanguage = userLearningLang !== 'English' ?  userLearningLang : userLang;

  const initialChat = `Hi, I'm ${character.charName}.`;

  const [session, setSession] = useState<any>(null);
  const [userID, setUserID]=  useState<string>("");


  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioData, setAudioData] = useState<Blob | null>(null);

  const [chatScene, setChatScene] = useState("")
  

const handlePointerDown = (e:any) => {
  // Check if the device is a touch screen device
  if (e.pointerType === 'touch') {
    startRecording();
  } else if (e.pointerType === 'mouse') {
    // Prevent default behavior for mouse events
    e.preventDefault();
    startRecording();
  }
};

const handlePointerUp = (e:any) => {
  // Check if the device is a touch screen device
  if (e.pointerType === 'touch') {
    stopRecording();
  } else if (e.pointerType === 'mouse') {
    // Prevent default behavior for mouse events
    e.preventDefault();
    stopRecording();
  }
};

const handleRecClick = async () => {
  if (isRecording) {
    stopRecording();
    setIsRecording(false);
  } else {
    startRecording();
    setIsRecording(true);
  }
};

const startRecording = async () => {
  console.log('startRecording')
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    recorder.ondataavailable = (e) => {
        setAudioData(e.data);
    };

    recorder.start();
    setIsRecording(true);
  } catch (err) {
    console.error('Error accessing audio devices.', err);
  }

};

useEffect(() => {
  if (audioData) {
    // Call the function you want to run when audioData updates
    sendAudio();
  }
}, [audioData]); 

const stopRecording = () => {
  console.log('stop recording..')
  if (mediaRecorder) {
    console.log('stopping..')
    mediaRecorder.stop();
    setIsRecording(false);
  }
};

const sendAudio = async () => {
  if (audioData) {
    const reader = new FileReader();
    reader.readAsDataURL(audioData);
    reader.onloadend = async () => {
      const base64AudioMessage = reader.result?.toString();
      console.log('sending audio');
      const response2 = await supabase.functions.invoke("openai-whisper-stt", {
        body: JSON.stringify({
          base64string:base64AudioMessage,
          language: 'th',
        })
      });
    console.log(response2.data);

    const transcription = JSON.parse(response2.data)
    
    setAudioData(null);
    dispatch(setOptions([]));

    if(transcription.text.length >0 ){
      // const id = messages.length 
      // dispatch(addMessage({ 
      //   role: 'user', 
      //   content: transcription.text, 
      //   translation: '', 
      //   userTranslation: '', 
      //   english: '',
      //   speaker: userName,
      //   chatID: chatId, 
      //   msgID: id, 
      //   hasNewTranslation: false
      // }));
      // dispatch(fetchResponse(messages, input, '', '', chatId, userID, id, userLang, userLearningLang, userGender, character.charGender, userName, character.charName, character.charProfile));
      // setTimeout(() => {
      //     scrollToBottom();
      // }, 100);
      // setInput('');
      if(transcription.text !== 'โปรดติดตามตอนต่อไป') {
        setInput(input + transcription.text);
      }
      };
    }
  }else {
    console.log('no audio data')
  }
};

const setScene = async () => {
  if( userName !== "") {
 const promptTemplate = `Provide the context and scenario for a spoken conversation between ${character.charName} and ${userName}.
  
  ${character.charName} is ${character.charPersonality}. ${character.charProfile}

  ${character.charName} is ${character.charGender} and ${userName} is ${userGender}.

  This fictional conversation aims to help the end user learn a language, so come up with a scenario that enables conversation (only between the two characters), questions and answers, and helps in daily life of the end user.
  Do not make language a part of the the subject or context of the discussion.
  
  Respond only with one short paragraphs outlining the context to set the scene for the discussion. Keep it simple. do not include any dialogue.
  `;
  console.log(promptTemplate)
  const response = await supabase.functions.invoke("openai-completions", {
    body: JSON.stringify({
      query: promptTemplate,
      model: "gpt-4-1106-preview"
    })
  });
  console.log(response.data?.choices[0].message?.content)
  setChatScene(response.data?.choices[0].message?.content)
  }  
   // set scene
}

// useEffect(()=>{
//  setScene();
// }, []);


useEffect(() => {
  return () => {
    mediaRecorder?.stream.getTracks().forEach(track => track.stop());
  };
}, [mediaRecorder]);

  useEffect(
    () => {
      async function getUserSettings() {
        if (!!session) {
          setLoading(true);
          const { user } = session;
          let { data, error } = await supabase
            .from("user_settings")
            .select(`chat_name, gender, native_language, learning_language`)
            .eq("user_id", user.id)
            .single();

          if (error) {
            console.warn(error);
          } else if (data) {
            setUserName(data.chat_name);
            setUserGender(data.gender);
            setUserLang(data.native_language);
            setUserLearningLang(data.learning_language);
          }
          setLoading(false);
        }
      }
      getUserSettings();
    },
    [session]
  );

  let navigate = useNavigate();
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      let uid = session?.user?.id || "";
      if( uid === "") {
        return navigate("/");
      }
      setUserID(uid)
    });

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    const channel = supabase.channel('messages_changes')
    .on('postgres_changes', {
      event: '*',
      schema: 'public',
      table: 'messages',
      // filters for just this chat's messages
      filter: 'chat_id=eq.'+ chatId
    }, (payload) => {
      const eventType = payload.eventType
      // add translation
      if(eventType === 'UPDATE') {
        let trans: Translation = {
          msgID: payload?.new["msg_id"],
          translation: payload?.new["translated_content"],
          language: payload?.new["translated_language"],
        }
        dispatch(setMessageTranslation(trans))
      }
    }).subscribe()
    return () => {
      channel.unsubscribe();
    }
  }, [chatId, userID, dispatch]);

  useEffect(() => {
    const newid = uuidv4()
    setChatId(newid)
    dispatch(setMessages([]))
    dispatch(setOptions([]))
    dispatch(addMessage({ role: 'character', content: initialChat, translation: 'no translation', userTranslation: 'no translation', english: '', speaker: character.charName, chatID: newid, msgID: 0, hasNewTranslation: false}));
  }, [dispatch, initialChat]);

  useEffect(scrollToBottom, [messages]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    dispatch(setOptions([]));
    const id = messages.length 
    if(event) {
      event.preventDefault();
    }
    if (!input) return;
    dispatch(addMessage({ 
      role: 'user', 
      content: input, 
      translation: '', 
      userTranslation: '', 
      english: '',
      speaker: userName,
      chatID: chatId, 
      msgID: id, 
      hasNewTranslation: false
    }));
    dispatch(fetchResponse(messages, input, '', '', chatId, userID, id, userLang, userLearningLang, userGender, character.charGender, userName, character.charName, character.charProfile, chatScene));
    setTimeout(() => {
        scrollToBottom();
    }, 100);
    setInput('');
  };
  
  const handleTranslation = useCallback(
    (index: number) => {
      setShowTranslation(index);
      setTimeout(() => {
        setShowTranslation(null);
      }, 5000);
    },
    [], 
  );

  useEffect(() => {
    messages.forEach((message, index) => {
      if (message.hasNewTranslation) {
        handleTranslation(index);
        dispatch(resetHasNewTranslation(index));
      }
    });
  }, [messages, dispatch, handleTranslation]);

  const [showTranslation, setShowTranslation] = useState<number | null>(null); 

  const handleOption = (index: number) => {
    if(userTokens <= 0) return;
    console.log(options[index].english)
    const id = messages.length 

    //display option 
    let dispMsg, txMsg; 
    if(userLearningLang === 'English'){
      dispMsg = options[index].english
      txMsg = options[index].tx
    } else {
      dispMsg = options[index].tx
      txMsg = options[index].english
    }

    let transLang;
    if (showInLearningLanguage && userLearningLang === 'English'){
      transLang = userLang
    } else {
      transLang = userLearningLang
    }

    dispatch(addMessage({ role: 'user', content: dispMsg, translation: txMsg, userTranslation: '', english: options[index].english, speaker: userName, chatID: chatId, msgID: id, hasNewTranslation: true}));

    dispatch(fetchResponse( 
      messages, 
      options[index].english, // english message
      options[index].tx, //translated message
      transLang, // translated language
      chatId, 
      userID, 
      id, 
      userLang, 
      userLearningLang, 
      userGender, 
      character.charGender, 
      userName, 
      character.charName, 
      character.charProfile,
      chatScene,
      ));
    dispatch(setOptions([]));
    setTimeout(() => {
        scrollToBottom();
    }, 350);
    setInput('');
  }

  let showEnglishOpt = false;
  if (showInLearningLanguage) {
    userLearningLang === 'English' ? showEnglishOpt = true : showEnglishOpt = false
  } else {
    userLearningLang === 'English' ? showEnglishOpt = false : showEnglishOpt = true
  }

  return (
    <div className="AppContainer">
    <div className="phone">
      <div className="headerContainer">
      <img src={logo} className="chatLogoImage" alt="supalingo.ai" />
      {userID === '04124eda-af07-4391-a06d-560271db7988' && (
          <span className="userTokens"  onClick={(event) => {
            event.stopPropagation();
            dispatch(toggleNavModalOpen())
          }}>{userTokens}</span> 
        )}
      {showSettings ? (
    <FaTimes onClick={() => setShowSettings(!showSettings)} className="settingsIcon" />
  ) : (
    <FaCog onClick={() => setShowSettings(!showSettings)} className="settingsIcon" />
  )}
      {showSettings && (
        <div className="headerContents">
          <label>
            <span>Show suggested responses in {userLearningLang}</span>
            <Switch 
              checked={showInLearningLanguage} 
              onChange={setShowInLearningLanguage} 
              onColor="#ffffff"
              onHandleColor="#2693e6"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={20}
              width={48}
            />
          </label>
        </div>
      )}
      </div>

      <div className="chat">
        {chatScene}
      {messages.map((msg, index) => (<>
          <div className={`message ${msg.role}`} onClick={() => handleTranslation(index)} key={index}>
            <div className="message-user">{msg.role === 'character' ? character.charName : userName}</div>
            <div className="message-text">{msg.content}</div>
            {msg.translation !== "" && (
                <div className={`message-translation ${showTranslation === index ? 'show' : ''} ${msg.role}`}>{msg.translation}</div>
            )}
          </div> 
          </>
        ))}
        {isCharacterTyping && (
        <div className="message character typing">
            <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        )}
        {options.map((opt, index) => (
          <div className={`message options`} onClick={() => handleOption(index)} key={index}>
            <div className="message-text">{
              showEnglishOpt ? opt.english : opt.tx
            }</div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="input-area" onSubmit={handleSubmit}> 
      {userTokens > 0 && (
      <>
      <div style={{ userSelect: 'none' }}></div>
        <input
          value={input}
          onChange={e => setInput(e.target.value)}
          placeholder="Type a message"
          className="message-input"
        />
        <button type="submit" className="submit-button">Send</button>  
        <div
           onClick={handleRecClick}
           //onPointerUp={handlePointerUp}
           onContextMenu={(e) => e.preventDefault()} // Prevents the contextual menu on mouse down
          className="submit-button"
          style={{ userSelect: 'none' }} // Prevents text selection on mobile browsers
            >
          {isRecording ? '...' : 'REC'}
        </div>
      </>  
      )}
    </form>
    </div>
    </div>
  );
}

export default ChatScreen;