import React from "react";
import { Link } from "react-router-dom";
import logo from "./images/supalingo.png";

const PrivacyScreen: React.FC = () => {
  return (
    <div className="AppContainer">
      <img src={logo} className="logoImage" alt="supalingo.ai" />
      <br />
      <div className="privacy-container">
        <h1>supalingo.ai Privacy Policy</h1>

        <h2>1. Introduction</h2>
        <p>
          This Privacy Policy describes the personal data collected or generated
          when you use supalingo.ai (hereinafter referred to as "Our Service").
          It also explains how your personal data is used, shared, and
          protected, what choices you have relating to your personal data, and
          how you can contact us.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          When you use Our Service, we collect the following types of personal
          data:
        </p>

        <h3>Information You Provide</h3>
        <p>
          This includes personal data you provide when you create an account,
          such as your name, email address, and profile picture.
        </p>

        <h3>Usage Information</h3>
        <p>
          We collect information about how you use Our Service. This may include
          the time, frequency, and duration of your visits, your interaction
          with AI bots, and the chat messages you send and receive.
        </p>

        <h2>3. Public Information</h2>
        <p>
          Our Service provides a platform for users to chat with AI bots. All
          chats are retained and made public to other users. This means that the
          content of your chats, along with your user name, may be visible to
          other users of Our Service.
        </p>

        <p>
          Please keep this in mind when deciding what information to share in
          your chats. We strongly recommend that you do not share sensitive
          information, such as your address, financial information, or other
          personally identifiable information, in your chats.
        </p>

        <h2>4. How We Use Your Information</h2>
        <p>We use your information for the following purposes:</p>

        <h3>To Provide and Improve Our Service</h3>
        <p>
          We use your information to provide Our Service, facilitate
          communication between you and the AI bots, enhance user experience,
          troubleshoot issues, and improve the performance and security of Our
          Service.
        </p>

        <h3>To Personalize Your Experience</h3>
        <p>
          We use your information to understand your preferences and tailor the
          features and content of Our Service to you.
        </p>

        <h2>5. Sharing Your Information</h2>
        <p>
          We do not share your personal information, such as your email address,
          with third parties for their marketing purposes without your consent.
        </p>

        <p>
          We may share your information with service providers who assist us in
          providing Our Service, such as hosting providers and analytics
          providers. These service providers are contractually obligated to
          protect your information and may not use it for purposes other than to
          provide services to us.
        </p>

        <p>
          In certain circumstances, we may also disclose your information if
          required by law or in response to valid requests by public
          authorities.
        </p>

        <h2>6. Protecting Your Information</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information. These measures include encryption,
          firewalls, secure socket layer technology, and other methods.
        </p>

        <p>
          However, no method of transmission over the internet or electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your personal information, we cannot guarantee its
          absolute security.
        </p>

        <h2>7. Your Rights and Choices</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. You can exercise these rights by contacting us at the
          contact information provided below.
        </p>

        <h2>8. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page and
          updating the "effective date" at the top of this Privacy Policy.
        </p>

        <h2>9. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at: <b>contact@supalingo.ai</b>
        </p>

        <p>
          <b>Effective Date: June 17, 2023</b>
        </p>
      </div>
      <Link className="footerLink" to="/">
        Home
      </Link>
      <br />
      <br />
    </div>
  );
};

export default PrivacyScreen;
