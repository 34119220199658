import React from "react";
import { Link } from "react-router-dom";
import logo from "./images/supalingo.png";

const TermsScreen: React.FC = () => {
  return (
    <div className="AppContainer">
      <img src={logo} className="logoImage" alt="supalingo.ai" />
      <br />
      <div className="privacy-container">
        <h1>supalingo.ai Terms of Service</h1>
        <p>Last updated: June 17, 2023</p>
        <p>
          Welcome to supalingo.ai, a web-based application that delivers an
          interactive language learning experience through AI chatbots. Kindly
          read these Terms of Service ("Terms") carefully before using the
          supalingo.ai services ("Services").
        </p>
        <p>
          By accessing and using our Services, you agree to abide by these
          Terms. If you do not agree with all these Terms, refrain from using
          the Services.
        </p>

        <h2>1. Account Registration</h2>
        <p>
          To utilize our Services, it's mandatory to create an account. You can
          sign up using your existing social accounts, and supabase.com handles
          authentication through these third parties. You agree to provide true,
          accurate, and current information when registering and to update this
          data as necessary.
        </p>

        <h2>2. Privacy</h2>
        <p>
          Your privacy is important to us. Please review our Privacy Policy to
          understand how we collect, use, and disclose your information.
        </p>

        <h2>3. supatokens</h2>
        <p>
          Our Services use virtual tokens, known as 'supatokens'. You can
          purchase supatokens to engage in chats with our AI chatbots.
          supatokens are deducted for each chatbot message response, background
          translations of user input messages, and other necessary computations
          required to provide the chatbot service. Tokens are non-refundable,
          non-transferable and do not expire.
        </p>

        <h2>4. Payment</h2>
        <p>
          supatokens can be procured in-app through a third-party payment
          provider, Stripe. You agree to comply with Stripe's terms and
          conditions and acknowledge that we are not responsible for any issues
          related to the processing of payments.
        </p>

        <h2>5. Subscription Service</h2>
        <p>
          supalingo.ai also offers a subscription option. By subscribing, you
          agree to pay the monthly fee and, in return, will receive a set number
          of supatokens into your account each month. Your subscription will
          automatically renew each month unless and until you cancel your
          subscription or we terminate it.
        </p>

        <h2>6. Usage and Restrictions</h2>
        <p>
          You agree to use our Services for personal and non-commercial use
          only. You agree not to use our Services to upload, post, transmit, or
          otherwise distribute any content that is unlawful, defamatory,
          harassing, abusive, fraudulent, obscene, or otherwise objectionable.
        </p>

        <h2>7. Intellectual Property Rights</h2>
        <p>
          All right, title, and interest in and to the supalingo.ai Services are
          and will remain the exclusive property of supalingo.ai and its
          licensors. Except as expressly provided herein, nothing in the Terms
          gives you a right to use the supalingo.ai name or any of the
          supalingo.ai trademarks, logos, domain names, and other distinctive
          brand features.
        </p>

        <h2>8. Changes to Terms</h2>
        <p>
          supalingo.ai reserves the right to modify these Terms at any time. If
          we make changes to these Terms, we will provide notice of such
          changes. Your continued use of the Services following notice of the
          changes to these Terms constitutes your acceptance of our amended
          Terms.
        </p>

        <h2>9. Termination</h2>
        <p>
          You agree that supalingo.ai, in its sole discretion, may suspend or
          terminate your account and your access to the Services for any reason,
          including, without limitation, for lack of use or if supalingo.ai
          believes that you have violated or acted inconsistently with the
          letter or spirit of these Terms.
        </p>

        <h2>10. Limitation of Liability</h2>
        <p>
          To the maximum extent permitted by applicable law, supalingo.ai shall
          not be liable for any indirect, incidental, special, consequential, or
          punitive damages, or any loss of profits or revenues, whether incurred
          directly or indirectly, or any loss of data, use, goodwill, or other
          intangible losses.
        </p>

        <h2>11. Governing Law</h2>
        <p>
          These Terms shall be governed by the laws of the state where
          supalingo.ai is based, without respect to its conflict of laws
          principles.
        </p>

        <p>
          Please contact us at <b>contact@supalingo.ai</b> with any questions
          regarding these Terms.
        </p>
      </div>
      <Link className="footerLink" to="/">
        Home
      </Link>{" "}
      <br />
      <br />
    </div>
  );
};

export default TermsScreen;
