import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCharacterState, selectCharacter } from "./characterSlice";

import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import logo from "./images/supalingo.png";
import { characterData } from "./characterData"; // import the character data
import { FaCog, FaTimes } from "react-icons/fa";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);

const GetStarted: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [hasProfile, setHasProfile] = useState(false);
  const [uid, setUid] = useState("");

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(
    () => {
      async function getUserSettings() {
        if (!!session) {
          setLoading(true);
          const { user } = session;
          setUid(user.id);
          let { data, error } = await supabase
            .from("user_settings")
            .select(`chat_name, gender, native_language, learning_language`)
            .eq("user_id", user.id)
            .single();

          if (error) {
            console.warn(error);
            console.log("no data");
          } else if (data) {
            setHasProfile(true);
            console.log(data);
          }
          setLoading(false);
        } else {
          setUid("");
        }
      }
      getUserSettings();
    },
    [session]
  );

  const characterState = useSelector(selectCharacter);
  const dispatch = useDispatch();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    dispatch(
      setCharacterState({ ...characterState, [e.target.id]: e.target.value })
    );
  };

  const handleCharacterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCharacter = characterData.find(
      char => char.charName === e.target.value
    );
    if (selectedCharacter) {
      dispatch(setCharacterState(selectedCharacter));
    }
  };

  return <div className="AppContainer" />;
};

export default GetStarted;
