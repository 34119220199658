import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  buttonStyle?: "regular" | "action" | "alert" | "flush";
}

export function Button({
  label = "Okay",
  buttonStyle = "regular",
  ...rest
}: ButtonProps) {
  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    // fontFamily: "'Roboto Mono', monospace",
    fontSize: "14px",
    // fontOpticalSizing: "auto",
    // fontWeight: 400,
    fontStyle: "normal",
    border: "none",
    backgroundColor: "#ececf1",
    color: "#101010",
    borderRadius: "1000px",
    padding: "8px 24px",
    minHeight: "12px",
    transition: "transform 0.1s ease-in-out, background-color 0.1s ease-in-out",
    outline: "none",
    ...buttonStyle === "action" && {
      backgroundColor: "#101010",
      color: "#ececf1",
      ":hover": {
        backgroundColor: "#404040"
      }
    },
    ...buttonStyle === "alert" && {
      backgroundColor: "#f00",
      color: "#ececf1",
      ":hover": {
        backgroundColor: "#f00"
      }
    },
    ...buttonStyle === "flush" && {
      backgroundColor: "rgba(255, 255, 255, 0)"
    },
    ":disabled": {
      color: "#999"
    },
    ":not(:disabled)": {
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#d8d8d8"
      },
      ":active": {
        transform: "translateY(1px)"
      }
    }
  };

  return (
    <button data-component="Button" style={buttonStyles} {...rest}>
      <span className="label">
        {label}
      </span>
    </button>
  );
}
