import React from "react";
import logo from "./images/supalingosmallwhite.png"; // Adjust the path if necessary

import { useEffect, useRef, useCallback, useState } from 'react';
import { createClient } from "@supabase/supabase-js";
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from './lib/wavtools/index.js';
import { instructions } from './utils/conversation_config.js';
import { WavRenderer } from './utils/wav_renderer';
import { Button } from './components/button/Button';
import {
    FaMicrophone
  } from "react-icons/fa"; // Import Font Awesome icons

import { registerTools } from './utils/registerTools';

const LOCAL_RELAY_SERVER_URL: string =
    process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

interface RealtimeEvent {
    time: string;
    source: 'client' | 'server';
    count?: number;
    event: { [key: string]: any };
}

const RealtimeScreen: React.FC = () => {
    const [input, setInput] = useState<string>("");
    const apiKey = LOCAL_RELAY_SERVER_URL
        ? ''
        : localStorage.getItem('tmp::voice_api_key') ||
        prompt('OpenAI API Key') ||
        '';
    if (apiKey !== '') {
        localStorage.setItem('tmp::voice_api_key', apiKey);
    }

    const wavRecorderRef = useRef<WavRecorder>(
        new WavRecorder({ sampleRate: 24000 })
    );
    const wavStreamPlayerRef = useRef<WavStreamPlayer>(
        new WavStreamPlayer({ sampleRate: 24000 })
    );
    const clientRef = useRef<RealtimeClient>(
        new RealtimeClient(
            LOCAL_RELAY_SERVER_URL
                ? { url: LOCAL_RELAY_SERVER_URL }
                : {
                    apiKey: apiKey,
                    dangerouslyAllowAPIKeyInBrowser: true,
                }
        )
    );

    /**
     * References for
     * - Rendering audio visualization (canvas)
     * - Autoscrolling event logs
     * - Timing delta for event log displays
     */
    const clientCanvasRef = useRef<HTMLCanvasElement>(null);
    const serverCanvasRef = useRef<HTMLCanvasElement>(null);
    const eventsScrollHeightRef = useRef(0);
    const eventsScrollRef = useRef<HTMLDivElement>(null);
    const startTimeRef = useRef<string>(new Date().toISOString());

    /**
     * All of our variables for displaying application state
     * - items are all conversation items (dialog)
     * - realtimeEvents are event logs, which can be expanded
     * - memoryKv is for set_memory() function
     * - coords, marker are for get_weather() function
     */
    const [items, setItems] = useState<ItemType[]>([]);
    const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
    const [expandedEvents, setExpandedEvents] = useState<{
        [key: string]: boolean;
    }>({});
    const [isConnected, setIsConnected] = useState(false);
    const [canPushToTalk, setCanPushToTalk] = useState(true);
    const [isRecording, setIsRecording] = useState(false);
    const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
    //   const [coords, setCoords] = useState<Coordinates | null>({
    //     lat: 37.775593,
    //     lng: -122.418137,
    //   });
    //   const [marker, setMarker] = useState<Coordinates | null>(null);

    const chatRef = useRef<HTMLDivElement>(null);

    /**
     * Utility for formatting the timing of logs
     */
    const formatTime = useCallback((timestamp: string) => {
        const startTime = startTimeRef.current;
        const t0 = new Date(startTime).valueOf();
        const t1 = new Date(timestamp).valueOf();
        const delta = t1 - t0;
        const hs = Math.floor(delta / 10) % 100;
        const s = Math.floor(delta / 1000) % 60;
        const m = Math.floor(delta / 60_000) % 60;
        const pad = (n: number) => {
            let s = n + '';
            while (s.length < 2) {
                s = '0' + s;
            }
            return s;
        };
        return `${pad(m)}:${pad(s)}.${pad(hs)}`;
    }, []);

    /**
     * When you click the API key
     */
    const resetAPIKey = useCallback(() => {
        const apiKey = prompt('OpenAI API Key');
        if (apiKey !== null) {
            localStorage.clear();
            localStorage.setItem('tmp::voice_api_key', apiKey);
            window.location.reload();
        }
    }, []);

    /**
     * Connect to conversation:
     * WavRecorder takes speech input, WavStreamPlayer output, client is API client
     */
    const connectConversation = useCallback(async () => {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        // Set state variables
        startTimeRef.current = new Date().toISOString();
        setIsConnected(true);
        setRealtimeEvents([]);
        setItems(client.conversation.getItems());

        // Connect to microphone
        await wavRecorder.begin();

        // Connect to audio output
        await wavStreamPlayer.connect();

        // Connect to realtime API
        await client.connect();

        if (client.getTurnDetectionType() === 'server_vad') {
            await wavRecorder.record((data) => client.appendInputAudio(data.mono));
        }
    }, []);

    useEffect(() => {
        connectConversation();
    }, []);

    /**
     * Disconnect and reset conversation state
     */
    const disconnectConversation = useCallback(async () => {
        setIsConnected(false);
        setRealtimeEvents([]);
        setItems([]);
        setMemoryKv({});
        // setCoords({
        //   lat: 37.775593,
        //   lng: -122.418137,
        // });
        // setMarker(null);

        const client = clientRef.current;
        client.disconnect();

        const wavRecorder = wavRecorderRef.current;
        await wavRecorder.end();

        const wavStreamPlayer = wavStreamPlayerRef.current;
        await wavStreamPlayer.interrupt();
    }, []);

    const deleteConversationItem = useCallback(async (id: string) => {
        const client = clientRef.current;
        client.deleteItem(id);
    }, []);

    /**
     * In push-to-talk mode, start recording
     * .appendInputAudio() for each sample
     */
    const startRecording = async () => {
        setIsRecording(true);
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;
        const trackSampleOffset = await wavStreamPlayer.interrupt();
        if (trackSampleOffset?.trackId) {
            const { trackId, offset } = trackSampleOffset;
            await client.cancelResponse(trackId, offset);
        }
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    };

    /**
     * In push-to-talk mode, stop recording
     */
    const stopRecording = async () => {
        setIsRecording(false);
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        await wavRecorder.pause();
        client.createResponse();
    };

    const sendTextResponse = async () => {
        const client = clientRef.current;
        client.sendUserMessageContent([
            {
                type: `input_text`,
                text: input,
            },
        ]);
        setInput('');
    }

    /**
     * Switch between Manual <> VAD mode for communication
     */
    const changeTurnEndType = async (value: string) => {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        if (value === 'none' && wavRecorder.getStatus() === 'recording') {
            await wavRecorder.pause();
        }
        client.updateSession({
            turn_detection: value === 'none' ? null : { type: 'server_vad' },
        });
        if (value === 'server_vad' && client.isConnected()) {
            await wavRecorder.record((data) => client.appendInputAudio(data.mono));
        }
        setCanPushToTalk(value === 'none');
    };

    /**
     * Auto-scroll the event logs
     */
    useEffect(() => {
        if (eventsScrollRef.current) {
            const eventsEl = eventsScrollRef.current;
            const scrollHeight = eventsEl.scrollHeight;
            // Only scroll if height has just changed
            if (scrollHeight !== eventsScrollHeightRef.current) {
                eventsEl.scrollTop = scrollHeight;
                eventsScrollHeightRef.current = scrollHeight;
            }
        }
    }, [realtimeEvents]);

    /**
     * Auto-scroll the conversation logs
     */
    useEffect(() => {
        const conversationEls = [].slice.call(
            document.body.querySelectorAll('[data-conversation-content]')
        );
        for (const el of conversationEls) {
            const conversationEl = el as HTMLDivElement;
            conversationEl.scrollTop = conversationEl.scrollHeight;
        }
    }, [items]);

    /**
     * Set up render loops for the visualization canvas
     */
    useEffect(() => {
        let isLoaded = true;

        const wavRecorder = wavRecorderRef.current;
        const clientCanvas = clientCanvasRef.current;
        let clientCtx: CanvasRenderingContext2D | null = null;

        const wavStreamPlayer = wavStreamPlayerRef.current;
        const serverCanvas = serverCanvasRef.current;
        let serverCtx: CanvasRenderingContext2D | null = null;

        const render = () => {
            if (isLoaded) {
                if (clientCanvas) {
                    if (!clientCanvas.width || !clientCanvas.height) {
                        clientCanvas.width = clientCanvas.offsetWidth;
                        clientCanvas.height = clientCanvas.offsetHeight;
                    }
                    clientCtx = clientCtx || clientCanvas.getContext('2d');
                    if (clientCtx) {
                        clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
                        const result = wavRecorder.recording
                            ? wavRecorder.getFrequencies('voice')
                            : { values: new Float32Array([0]) };
                        WavRenderer.drawBars(
                            clientCanvas,
                            clientCtx,
                            result.values,
                            '#0099ff',
                            10,
                            0,
                            8
                        );
                    }
                }
                if (serverCanvas) {
                    if (!serverCanvas.width || !serverCanvas.height) {
                        serverCanvas.width = serverCanvas.offsetWidth;
                        serverCanvas.height = serverCanvas.offsetHeight;
                    }
                    serverCtx = serverCtx || serverCanvas.getContext('2d');
                    if (serverCtx) {
                        serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
                        const result = wavStreamPlayer.analyser
                            ? wavStreamPlayer.getFrequencies('voice')
                            : { values: new Float32Array([0]) };
                        WavRenderer.drawBars(
                            serverCanvas,
                            serverCtx,
                            result.values,
                            '#009900',
                            10,
                            0,
                            8
                        );
                    }
                }
                window.requestAnimationFrame(render);
            }
        };
        render();

        return () => {
            isLoaded = false;
        };
    }, []);

    /**
     * Core RealtimeClient and audio capture setup
     * Set all of our instructions, tools, events and more
     */
    useEffect(() => {
        // Get refs
        const wavStreamPlayer = wavStreamPlayerRef.current;
        const client = clientRef.current;

        // Set instructions
        client.updateSession({ instructions: instructions });
        client.updateSession({voice:"echo"})
        // Set transcription, otherwise we don't get user transcriptions back
        client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

        // Add tools
        registerTools(client);

        // handle realtime events from client + server for event logging
        client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
            setRealtimeEvents((realtimeEvents) => {
                const lastEvent = realtimeEvents[realtimeEvents.length - 1];
                if (lastEvent?.event.type === realtimeEvent.event.type) {
                    // if we receive multiple events in a row, aggregate them for display purposes
                    lastEvent.count = (lastEvent.count || 0) + 1;
                    return realtimeEvents.slice(0, -1).concat(lastEvent);
                } else {
                    return realtimeEvents.concat(realtimeEvent);
                }
            });
        });
        client.on('error', (event: any) => console.error(event));
        client.on('conversation.interrupted', async () => {
            const trackSampleOffset = await wavStreamPlayer.interrupt();
            if (trackSampleOffset?.trackId) {
                const { trackId, offset } = trackSampleOffset;
                await client.cancelResponse(trackId, offset);
            }
        });
        client.on('conversation.updated', async ({ item, delta }: any) => {
            const items = client.conversation.getItems();
            if (delta?.audio) {
                wavStreamPlayer.add16BitPCM(delta.audio, item.id);
            }
            if (item.status === 'completed' && item.formatted.audio?.length) {
                const wavFile = await WavRecorder.decode(
                    item.formatted.audio,
                    24000,
                    24000
                );
                item.formatted.file = wavFile;
            }
            setItems(items);
        });

        setItems(client.conversation.getItems());

        return () => {
            // cleanup; resets to defaults
            client.reset();
        };
    }, []);

    /**
     * Auto-scroll the chat area
     */
    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [items]);

    return (

        <div className="AppContainer">
            <div className="phone">
                <div className="headerContainerRealtime">
                    <img src={logo} className="chatLogoImage" alt="supalingo.ai" />
                    {/* <Toggle
                        defaultValue={false}
                        labels={['manual', 'vad']}
                        values={['none', 'server_vad']}
                        onChange={(_, value) => changeTurnEndType(value)}
                    /> */}
                    <div className="content-api-key">
                    {!LOCAL_RELAY_SERVER_URL &&(
                        <Button
                        buttonStyle="flush"
                        label={ apiKey === '' ? "Set API Key" : "API"}
                        onClick={() => resetAPIKey()}
                        />
                    )}
                    </div>
                    <Button
                        label={isConnected ? 'disconnect' : 'connect'}
                        buttonStyle={isConnected ? 'regular' : 'action'}
                        onClick={
                            isConnected ? disconnectConversation : connectConversation
                        }
                    />
                </div>
                <div className="chat" ref={chatRef}>
                    <div className="content-block conversation">
                        <div className="content-block-body" data-conversation-content>
                            {!isConnected && `awaiting connection...`}
                            {items.map((conversationItem, i) => {
                                return (
                                    <div className="conversation-item" key={conversationItem.id} style={{flexDirection: "column"}}>
                                        <div className={`speaker ${conversationItem.role || ''}`}>
                                            <div>
                                                {(
                                                    conversationItem.role || conversationItem.type
                                                ).replaceAll('_', ' ')}
                                            </div>
                                        </div>
                                        <div className={`speaker-content`}>
                                            {conversationItem.type === 'function_call_output' && (
                                                <div style={{fontSize: "6px"}}>{conversationItem.formatted.output}</div>
                                            )}
                                            {!!conversationItem.formatted.tool && (
                                                <div style={{fontSize: "6px"}} >
                                                    {conversationItem.formatted.tool.name}(
                                                    {conversationItem.formatted.tool.arguments})
                                                </div>
                                            )}
                                            {!conversationItem.formatted.tool &&
                                                conversationItem.role === 'user' && (
                                                    <div>
                                                        {conversationItem.formatted.transcript ||
                                                            (conversationItem.formatted.audio?.length
                                                                ? '(awaiting transcript)'
                                                                : conversationItem.formatted.text ||
                                                                '(item sent)')}
                                                    </div>
                                                )}
                                            {!conversationItem.formatted.tool &&
                                                conversationItem.role === 'assistant' && (
                                                    <div>
                                                        {conversationItem.formatted.transcript ||
                                                            conversationItem.formatted.text ||
                                                            '(truncated)'}
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="contenActionsRealtime" style={{ userSelect: 'none' }}> 
                    {isConnected && canPushToTalk && (
                        <div
                            className={`custom-mic-button ${isRecording ? 'recording' : ''}`}
                            onTouchStart={startRecording}
                            onTouchEnd={stopRecording}
                            onMouseDown={startRecording}
                            onMouseUp={stopRecording}
                        >
                            <FaMicrophone style={{ margin: "0 5px", cursor: "pointer", width: "30px", height: "30px", userSelect: 'none'  }} />
                        </div>
                    )}
                </div>
                
                <div className="input-area" style={{ userSelect: 'none' }}>
                    {isConnected && (
                    <>
                        <input
                        value={input}
                        onChange={e => setInput(e.target.value)}
                        placeholder="Type a message"
                        className="message-input"
                        />
                        <button disabled={!isConnected} className="submit-button" onClick={sendTextResponse}>Send</button>  
                    </>
                    )}
                </div>

            </div>
        </div>
    );
};

export default RealtimeScreen;