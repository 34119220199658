import { RealtimeClient } from "@openai/realtime-api-beta";
import { createClient } from "@supabase/supabase-js";
const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

export const registerTools = (client: RealtimeClient) => {
  client.addTool(
    {
      name: "get_pin_work",
      description:
        "Gets Pin's work schedule. The query should be a question that can be answered by the work schedule.",
      parameters: {
        type: "object",
        properties: {
          query: {
            type: "string",
            description:
              "A question that can be answered by the work schedule. eg: 'What is Pin's schedule on October 10th, 2024?'"
          }
        },
        required: ["query"]
      }
    },
    async ({ query }: { query: string }) => {
      console.log("called get pin work function");
      const response = await supabase.functions.invoke("n8n-ask-pin-work", {
        body: JSON.stringify({
          query: query
        })
      });

      const resp = JSON.parse(response.data);
      console.log(resp);
      return resp;
    }
  );

  client.addTool(
    {
      name: "get_weather",
      description:
        "Retrieves the weather for a given lat, lng coordinate pair. Specify a label for the location.",
      parameters: {
        type: "object",
        properties: {
          lat: {
            type: "number",
            description: "Latitude"
          },
          lng: {
            type: "number",
            description: "Longitude"
          },
          location: {
            type: "string",
            description: "Name of the location"
          }
        },
        required: ["lat", "lng", "location"]
      }
    },
    async ({ lat, lng, location }: { [key: string]: any }) => {
      const result = await fetch(
        `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
      );
      const json = await result.json();
      return json;
    }
  );

  client.addTool(
    {
      name: "get_homeassistant_entity_state",
      description:
        "Retrieves entity state for the given homeassistant entity - a device in Dave's home. State refers to whether it's on or off, generally.",
      parameters: {
        type: "object",
        properties: {
          entityID: {
            type: "string",
            description: "the entity ID for the homeassistant entity"
          }
        },
        required: ["entityID"]
      }
    },
    async ({ entityID }: { entityID: string }) => {
      const response = await supabase.functions.invoke("get-ha-entity-state", {
        body: JSON.stringify({
          entityID: entityID
        })
      });
      //console.log(response2.data);

      const rawWordResp = JSON.parse(response.data);
      console.log(rawWordResp);
      return rawWordResp;
    }
  );

  client.addTool(
    {
      name: "switch_homeassistant_entity_state",
      description:
        "Switches the entity state of a homeassistant entity. Use this to turn lights on and off, AC on and off etc. for lights and switches, use turn_on and turn_off as the newState. Call get_homeassistant_entity_list before calling this tool, to ensure you use the correct entity ID. ",
      parameters: {
        type: "object",
        properties: {
          entityID: {
            type: "string",
            description: "the entity ID for the homeassistant entity"
          },
          newState: {
            type: "string",
            description:
              "the new state for the homeassistant entity. (turn_on, turn_off)"
          }
        },
        required: ["entityID", "newState"]
      }
    },
    async ({ entityID, newState }: { entityID: string; newState: string }) => {
      const response = await supabase.functions.invoke(
        "switch-ha-entity-state",
        {
          body: JSON.stringify({
            entityID: entityID,
            newState: newState
          })
        }
      );
      console.log(response);
      return response;
    }
  );

  client.addTool(
    {
      name: "get_homeassistant_entity_list",
      description:
        "Retrieves entity list for homeassistant. Use to determine which entity to call in the get-ha-entity-state tool if Dave asks about the status of things in his home. entities starting with light, climate and switch are able to be turned on and off.",
      parameters: {}
    },
    async () => {
      const entityList = {
        "Office reading lamp": "light.zigbee_light_reading",
        "Office air conditiononer (AC)": "climate.office_ac",
        "Office door": "binary_sensor.office_door_sensor_opening_2",
        "Kitchen aircon": "climate.kitchen_ac",
        "Guest bedroom aircon": "climate.guest_bed_ac",
        "Living room aircon": "climate.living_room_ac",
        "Living room light": "light.living_room_lights_light_6",
        "Entry area light": "light.tz3000_rbl8c85w_ts0012_light_7",
        "Garage / carport light": "light.front_door_lights_light_8",
        "Kitchen light": "light.kitchen_light_switch",
        "Master bedroom aircon": "climate.master_bed_ac",
        "Master bedroom light 1 (near bed)":
          "light.bedroom_light_switch_light_4",
        "Master bedroom light 2 (near closet)":
          "light.bedroom_light_switch_light_6",
        "Gate control (turn_on) to toggle the gate. always ask for confirmation.":
          "switch.gate_socket"
      };
      return entityList;
    }
  );

  client.addTool(
    {
      name: "get_calendars",
      description: "Retrieves a list of calendars available",
      parameters: {}
    },
    async ({ entityID }: { entityID: string }) => {
      const response = await supabase.functions.invoke("ha-get-calendars", {});
      //console.log(response2.data);

      const resp = JSON.parse(response.data);
      console.log(resp);
      return resp;
    }
  );

  client.addTool(
    {
      name: "get_calendar_events",
      description: "Retrieves a list of calendars available",
      parameters: {
        type: "object",
        properties: {
          start: {
            type: "string",
            description:
              "start time for the calendar events in ISO format with any '+' urlencoded eg: 2024-07-25T09:00:00%2B07:00"
          },
          end: {
            type: "string",
            description:
              "end time for the calendar events in ISO format with any '+' urlencoded eg: 2024-07-26T09:00:00%2B07:00"
          },
          entityID: {
            type: "string",
            description: "Entity ID for the calendar to retrieve events for"
          }
        },
        required: ["entityID", "start", "end"]
      }
    },
    async ({
      entityID,
      start,
      end
    }: {
      entityID: string;
      start: string;
      end: string;
    }) => {
      const response = await supabase.functions.invoke(
        "ha-get-calendar-events",
        {
          body: JSON.stringify({
            entityID: entityID,
            start: start,
            end: end
          })
        }
      );

      const resp = JSON.parse(response.data);
      console.log(resp);
      return resp;
    }
  );

  client.addTool(
    {
      name: "add_calendar_event",
      description:
        "Adds an event to a calendar. Must provide either start_date_time and end_date_time, or start_date and end_date but not both. Add new events to Dave's personal calendar, unless instructed otherwise.",
      parameters: {
        type: "object",
        properties: {
          entityID: {
            type: "string",
            description:
              "Entity ID for the calendar to add the event to, e.g. calendar.dave_sims_gmail_com"
          },
          summary: {
            type: "string",
            description: "Summary or title of the event"
          },
          start_date_time: {
            type: "string",
            description:
              "Start date and time of the event in ISO format, e.g., 2024-10-08T10:15:00+07:00"
          },
          end_date_time: {
            type: "string",
            description:
              "End date and time of the event in ISO format, e.g., 2024-10-08T10:30:00+07:00"
          },
          description: {
            type: "string",
            description: "Description of the event (optional)"
          },
          start_date: {
            type: "string",
            description: "Start date of the event (for all-day events)"
          },
          end_date: {
            type: "string",
            description: "End date of the event (for all-day events)"
          }
        },
        required: ["entityID", "summary"]
      }
    },
    async ({
      entityID,
      summary,
      start_date_time,
      end_date_time,
      description,
      start_date,
      end_date
    }: {
      entityID: string;
      summary: string;
      start_date_time?: string;
      end_date_time?: string;
      description?: string;
      start_date?: string;
      end_date?: string;
    }) => {
      const response = await supabase.functions.invoke(
        "ha-create-calender-event",
        {
          body: JSON.stringify({
            entityID: entityID,
            summary: summary,
            start_date_time: start_date_time,
            end_date_time: end_date_time,
            description: description,
            start_date: start_date,
            end_date: end_date
          })
        }
      );

      const resp = JSON.parse(response.data);
      console.log(resp);
      return resp;
    }
  );

  client.addTool(
    {
      name: "add_language_learning_word",
      description:
        "Adds a word to Dave's language learning vocabulary. Use this to add new words to Dave's language learning vocabulary.",
      parameters: {
        type: "object",
        properties: {
          word: {
            type: "string",
            description:
              "the (likely Thai) word to add to Dave's language learning vocabulary"
          },
          translation: {
            type: "string",
            description:
              "the English translation of the word to add to Dave's language learning vocabulary (keep it short)"
          }
        },
        required: ["word", "translation"]
      }
    },
    async ({ word, translation }: { word: string; translation: string }) => {
      await supabase.from("user_word_data").upsert({
        word: word,
        en: translation,
        user_id: "e053d8ef-3795-4812-8af9-1078421c4256",
        bookmarked: true
      });
      return { result: "success" };
    }
  );
};
