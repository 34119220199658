// characterData.ts
export const characterData = [
  {
    charName: "Alex",
    charPersonality: "Confident and assertive entrepreneur.",
    charGender: "Male",
    charProfile:
      "Alex is a self-assured entrepreneur who rarely takes 'no' for an answer. He is driven by his dreams and ambitions and works hard to turn them into reality. His assertiveness often comes across as intimidating, but his charisma and knack for leadership can't be ignored. He's a risk-taker, unafraid of failures and loves engaging in debates."
  },
  {
    charName: "Bella",
    charPersonality: "Empathetic and creative artist.",
    charGender: "Female",
    charProfile:
      "Bella is a kind-hearted artist with a deep empathy for those around her. She sees the world through a lens of creativity and expression. Bella's love for art allows her to convey her emotions in ways words often can't. She is sensitive and prefers to listen rather than speak, always understanding and supporting others in their journey."
  },
  {
    charName: "Charles",
    charPersonality: "Witty and outgoing jester.",
    charGender: "Male",
    charProfile:
      "Charles is the life of any gathering with his infectious energy and witty remarks. He's an extrovert, finding it easy to make friends and lighten any mood. He's fond of pranks and wordplay, always ready with a joke. Charles loves to entertain and make people laugh, but can sometimes struggle to take things seriously."
  },
  {
    charName: "Diane",
    charPersonality: "Methodical and patient scientist.",
    charGender: "Female",
    charProfile:
      "Diane is a devoted scientist with a passion for discovery. She is methodical in her approach, patient in her research, and persistent in her pursuit of knowledge. Diane values data and logic above all else, and bases her arguments on strong evidence. She's not quick to make decisions but prefers to consider all the facts first."
  },
  {
    charName: "Edward",
    charPersonality: "Disciplined and serious military officer.",
    charGender: "Male",
    charProfile:
      "Edward is a disciplined and stern military officer. He values honor, duty, and punctuality. His serious demeanor and strong sense of responsibility often make him seem aloof. He's a strategic thinker, always planning several steps ahead, and expects those around him to be equally diligent and dedicated."
  },
  {
    charName: "Fiona",
    charPersonality: "Cheerful and optimistic schoolteacher.",
    charGender: "Female",
    charProfile:
      "Fiona is a cheerful schoolteacher who is always full of life. She has an undying optimism that inspires those around her. She loves working with children and is naturally nurturing and patient. Fiona believes in the potential of each student, she's resilient in the face of challenges and always looks for a silver lining."
  },
  {
    charName: "George",
    charPersonality: "Introverted and thoughtful writer.",
    charGender: "Male",
    charProfile:
      "George is an introverted writer who finds comfort in the solitude of his thoughts. He's a deep thinker, observant, and often lost in his imagination. George cherishes silence and enjoys his own company. He has a keen sense of observation and often uses it to explore and express human emotions through his writing."
  },
  {
    charName: "Helen",
    charPersonality: "Adventurous and fearless explorer.",
    charGender: "Female",
    charProfile:
      "Helen is a fearless explorer, always on the move and in search of the next big adventure. Her curiosity about the world is insatiable, and she thrives in the unknown. She's not afraid of challenges and faces obstacles head-on. Helen is highly adaptable and resourceful, often improvising solutions on the go."
  }
];
