import React, { useState, useEffect, CSSProperties } from "react";
import { createClient } from "@supabase/supabase-js";
import GraphemeSplitter from "grapheme-splitter";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

const TypeSentencesScreen: React.FC = () => {
  const splitter = new GraphemeSplitter();
  const [session, setSession] = useState<any>(null);
  const [word, setWord] = useState("หลังจาก");
  const [typed, setTyped] = useState("");
  const [typedGraphemes, setTypedGraphemes] = useState<string[]>([]);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  let nextCharIndex = 0;

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const graphemes = splitter.splitGraphemes(word);
    const typedLength = typedGraphemes.join("").length;

    if (typedGraphemes.length < graphemes.length) {
      const currentGrapheme = graphemes[typedGraphemes.length];
      nextCharIndex = typed.length - typedLength;
      const nextChar = currentGrapheme[nextCharIndex];

      if (event.key === nextChar) {
        const newTyped = typed + event.key;
        setTyped(newTyped);
        // If the full grapheme is typed, move to the next one
        if (
          currentGrapheme ===
          newTyped.substring(newTyped.length - currentGrapheme.length)
        ) {
          setTypedGraphemes([...typedGraphemes, currentGrapheme]);
          // Handle completion of the grapheme
        }
      } else {
        // Handle the error
      }
    }
  };

  const startTyping = () => {
    setStarted(true);
  };

  const typedLength = typedGraphemes.join("").length;

  return (
    <div className="AppContainer" tabIndex={0} onKeyPress={handleKeyPress}>
      {!started && <button onClick={startTyping}>Start</button>}
      {started &&
        <div style={styles.wordContainer}>
          {splitter.splitGraphemes(word).map((grapheme, index) => {
            let color = "black";
            if (index < typedGraphemes.length) color = "grey";
            else if (
              index === typedGraphemes.length &&
              grapheme[nextCharIndex] === typed[typedLength]
            ) {
              color = "blue";
            } else if (
              typedGraphemes.length > index &&
              typedGraphemes[index] !== grapheme
            )
              color = "orange";

            return (
              <span key={index} style={{ ...styles.char, color: color }}>
                {grapheme}
              </span>
            );
          })}
        </div>}
    </div>
  );
};

const styles: { [key: string]: CSSProperties } = {
  blank: {
    fontWeight: "bold"
  },
  wordContainer: {
    fontSize: "32px",
    textAlign: "center",
    margin: "20px"
  },
  char: {
    fontWeight: "bold"
  }
};

export default TypeSentencesScreen;
