import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";
import { createClient } from "@supabase/supabase-js";
import { LexiconResult } from "./components/Constants";
import {
  FaVolumeUp
} from "react-icons/fa"; // Import Font Awesome icons
import FlipCard from "./flipcard";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

interface ReviewScreenProps {}

interface WordDataReverse {
  word: string;
  en: string;
  n_repetition_reverse: number;
  easiness_factor_reverse: number;
  inter_repetition_interval_reverse: number;
  last_review_date_reverse: string; // assuming ISO string format
  n_repetition: number;
  easiness_factor: number;
  inter_repetition_interval: number;
  last_review_date: string; // assuming ISO string format
  fully_learned: boolean;
  used_in_story?: string;
  marked_for_story?: string;
}


const applySM2Reverse = (wordData: WordDataReverse, grade: number): WordDataReverse => {
  let { n_repetition_reverse, easiness_factor_reverse, inter_repetition_interval_reverse } = wordData;

  // If the grade is 3 or more, it's a correct response.
  if (grade >= 3) {
    switch (n_repetition_reverse) {
      case 0:
        inter_repetition_interval_reverse = 1;
        break;
      case 1:
        inter_repetition_interval_reverse = 6;
        break;
      default:
        inter_repetition_interval_reverse = Math.round(
          inter_repetition_interval_reverse * easiness_factor_reverse
        );
        break;
    }
    n_repetition_reverse += 1;
  } else {
    // Otherwise, it's an incorrect response.
    n_repetition_reverse = 0;
    inter_repetition_interval_reverse = 1;
  }

  // Update easiness factor
  easiness_factor_reverse += 0.1 - (5 - grade) * (0.08 + (5 - grade) * 0.02);
  if (easiness_factor_reverse < 1.3) {
    easiness_factor_reverse = 1.3;
  }

  return {
    ...wordData,
    n_repetition_reverse,
    easiness_factor_reverse,
    inter_repetition_interval_reverse,
    last_review_date_reverse: new Date().toISOString() // Update the review date to current date
  };
};

const ReviewScreenEn: React.FC<ReviewScreenProps> = () => {
  const dispatch: AppDispatch = useDispatch();
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState("");
  const [reviewWords, setReviewWords] = useState<WordDataReverse[]>([]);
  const [wordsForStory, setwordsForStory] = useState<WordDataReverse[]>([]);
  const [showDefinition, setShowDefinition] = useState(false);
  const [sentence1, setSentence1] = useState("");
  const [sentence2, setSentence2] = useState("");
  const [sentence3, setSentence3] = useState("");
  
  const [sentencesViewed, setSentencesViewed] = useState(false)
  const [sentLoading, setSentLoading] = useState(false)
  
  const [explanation, setExplanation] = useState("");

  const [lexicon, setLexicon] = useState<LexiconResult[]>([]);



  function shuffle(array: any) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  useEffect(
    () => {
      console.log(currentWord)
      fetchLexicon(currentWord);
       },
    [currentWord]
  );

  const fetchLexicon = async (word: string) => {
    let { data: lexitron, error } = await supabase
      .from("lexitron_th_en")
      .select("*")
      .eq("thai_search", word);
    if (lexitron !== null) {
      console.log(lexitron);
      //setModalLexEng = lexitron[0].
      if (lexitron.length > 0) {
        setLexicon(lexitron as LexiconResult[]);
      } else {
        setLexicon([]);
      }
    }
  }

  // Fetch due words for review based on the SM-2 criteria
  const fetchDueWords = useCallback(
    async () => {

      if (session) {
        const now = new Date();
        //const currDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
        const currDate = new Date();
        const { data: words, error } = await supabase
          .from("user_word_data")
          .select("*")
          .eq("user_id", session.user.id)
          .or("inter_repetition_interval.gt.55,fully_learned.eq.true")
          .eq("fully_learned_reverse", false)
        console.log("Curr: " + new Date())
        if (words) {
          console.log(words.length)
          const wordsToReview = words.filter(word => {
            if (!word.last_review_date_reverse) {
                return true;  // Select words that have never been reviewed
            }

            const nextReviewDate = new Date(word.last_review_date_reverse);
            nextReviewDate.setDate(nextReviewDate.getDate() + word.inter_repetition_interval_reverse);
            nextReviewDate.setHours(0, 0, 0, 0);
            currDate.setHours(0, 0, 0, 0);

            return nextReviewDate <= currDate;
          });
          const shuffledWordsToReview = shuffle(wordsToReview);

          setReviewWords(shuffledWordsToReview as WordDataReverse[]);
          setCurrentWord(shuffledWordsToReview[0]?.word);
        }
      }
    },
    [session]
  );


  const fetchWordsForStory = useCallback(async () => {
    if (session) {
      const { data: words, error } = await supabase
        .from("user_word_data")
        .select("*")
        .eq("user_id", session.user.id)
        .lt("marked_for_story", new Date().toISOString())
        .is("used_in_story", null);

      if (error) {
        console.error("Error fetching words for story:", error);
      } else {
        // Handle the fetched words here
        setwordsForStory(words as WordDataReverse[])
        console.log("Words marked for story:", words);
      }
    }
  }, [session]);


  const handleGrade = async (grade: number) => {
    if (reviewWords && reviewWords[currentWordIndex]) {
      let wordData = reviewWords[currentWordIndex];

      // Apply the SM-2 algorithm
      wordData = applySM2Reverse(wordData, grade);
      delete wordData.used_in_story;
      delete wordData.marked_for_story;

      // set definition fully learned if recall graded 5
      if (reviewWords[currentWordIndex].fully_learned == false && grade == 5){
        wordData.fully_learned = true
        console.warn("SETTING FULLY LEAREND TRU E")
      }

      // Here, update the word attributes in the database.
      const { error } = await supabase
        .from("user_word_data")
        .update(wordData)
        .eq("word", wordData.word)
        .eq("user_id", session.user.id);

      if (error) {
        console.error("Error updating word data:", error);
      }

      // Move to next word after grading current word
      const newIndex = currentWordIndex + 1
      setCurrentWordIndex(prevIndex => prevIndex + 1);
      setCurrentWord(reviewWords[newIndex].word);
      setShowDefinition(false);
      setSentence1("");
      setSentence2("");
      setSentence3("");
      setSentencesViewed(false);
      setExplanation("")
     }
  };

  const getSentences = async (word: string) => {
    setSentLoading(true)
    setSentencesViewed(true);

    // bundle definitions
    let lexExtra = '';
    if (!!lexicon && lexicon[0]?.eng_entry.length > 0) {
      lexExtra = `The word has a possible translation of ` + lexicon[0]?.eng_entry + `. `
      if (lexicon[0].eng_alternate !== null && lexicon[0].eng_alternate.length > 0) {
        lexExtra += 'Alternative english translations could include ' + lexicon[0].eng_alternate + `
      `;
      }
    }

    // call function
    console.log(word)
    const response = await supabase.functions.invoke("openai-sentence-examples", {
      body: JSON.stringify({ 
        word: word,
        definitions: lexExtra,
        language: "Thai"
      })
    });


    const resp = JSON.parse(response.data)
    setSentence1(resp?.sentences?.one)
    setSentence2(resp?.sentences?.two)
    setSentence3(resp?.sentences?.three)
    console.log(resp)
    setSentLoading(false)
  };


  const getImage = async (word: string) => {
    // bundle definitions
    let lexExtra = '';
    if (!!lexicon && lexicon[0]?.eng_entry.length > 0) {
      lexExtra = `The word has a possible translation of ` + lexicon[0]?.eng_entry + `. `
      if (lexicon[0].eng_alternate !== null && lexicon[0].eng_alternate.length > 0) {
        lexExtra += 'Alternative english translations could include ' + lexicon[0].eng_alternate + `
      `;
      }
    }

    // call function
    console.log(word)
    supabase.functions.invoke("openai-generate-example-image", {
      body: JSON.stringify({ 
        word: word,
        definitions: lexExtra,
        language: "Thai"
      })
    });

  };

  const getExplanation = async() => {
    //
    setSentLoading(true)
    if(!showDefinition) {
      setShowDefinition(true)
    }
    console.log(reviewWords[currentWordIndex]?.word)
    let lexExtra = '';
    if (!!lexicon && lexicon[0]?.eng_entry.length > 0) {
      lexExtra = `The word has one possible translation of ` + lexicon[0]?.eng_entry + `. `
      if (lexicon[0]?.eng_alternate !== null && lexicon[0]?.eng_alternate.length > 0) {
        lexExtra += 'Alternative english translations could include ' + lexicon[0]?.eng_alternate + `
        `;
      }
     }
    const promptTemplate = `Provide a short explanation of the thai word ${reviewWords[currentWordIndex]?.word} for an English speaking Thai language learner. 

    ${lexExtra} Include examples of the word in context of thai sentences, along with explanations of the word in each context, especially if the word has multiple meanings in different contexts.
    Do not provide transliterations of thai language examples.
    `;
    console.log(promptTemplate)
    const response = await supabase.functions.invoke("openai-completions", {
      body: JSON.stringify({
        query: promptTemplate,
        model: "gpt-4-1106-preview"
      })
    });

    console.log(response.data?.choices[0].message?.content)
    setExplanation(response.data?.choices[0].message?.content)
    setSentLoading(false)
  }

  function handleSpeak(text: string) {
    if (window.responsiveVoice) {
      window.responsiveVoice.speak(text, "Thai Female", {
        rate: 0.75,
      });
    } else {
      console.error("ResponsiveVoice not loaded");
    }
  }

  const updateDefinition = async (word: string,newdef: string) => {
    console.log(newdef)
    const { error } = await supabase
    .from("user_word_data")
    .update({"en": newdef})
    .eq("word", word)
    .eq("user_id", session.user.id);

    if (error) {
      console.error("Error updating word data:", error);
    }
    reviewWords[currentWordIndex].en = newdef
  }

  const deleteWord = async (word: string) => {
    if (window.confirm("Are you sure you want to delete this word?")) {
      const { error } = await supabase
        .from("user_word_data")
        .delete()
        .eq("word", word)
        .eq("user_id", session.user.id);

      if (error) {
        console.error("Error deleting word data:", error);
      } else {
        nextWord();
      }
    }
  }

  const ignoreWord = async (word: string) => {
    const { error } = await supabase
    .from("user_word_data")
    .update({ fully_learned: true, fully_learned_reverse: true, ignored: true, bookmarked: true})
    .eq("word", word)
    .eq("user_id", session.user.id);

      if (error) {
        console.error("Error ignoring word:", error);
      } else {
        nextWord();
      }
  }

  const fullyLearned = async (word: string) => {
    const { error } = await supabase
      .from("user_word_data")
      .update({ fully_learned_reverse: true, fully_learned: true })
      .eq("word", word)
      .eq("user_id", session.user.id);

    if (error) {
      console.error("Error updating fully learned status:", error);
    } else {
      nextWord();
    }
  }


  const handleMarkedForStory = async (word: string) => {
    const { error } = await supabase
      .from("user_word_data")
      .update({ marked_for_story: new Date().toISOString() })
      .eq("word", word)
      .eq("user_id", session.user.id);

    if (error) {
      console.error("Error marking word for story:", error);
    }
    fetchWordsForStory();
  }

  const nextWord = () => {
    const newIndex = currentWordIndex + 1
      setCurrentWordIndex(prevIndex => prevIndex + 1);
      setCurrentWord(reviewWords[newIndex].word);
      setShowDefinition(false);
      setSentence1("");
      setSentence2("");
      setSentence3("");
      setSentencesViewed(false);
      setExplanation("")
  }

  useEffect(
    () => {
      fetchDueWords();
      fetchWordsForStory();
    },
    [session]
  );

  return (
    <div className="AppContainer" style={{ padding: "20px" }}>
      {reviewWords.length === 0 && (
         <h2>Nothing left to review.</h2>
      )}

    {reviewWords.length > 0 && (
      <>
      <div>{currentWordIndex+1}/{reviewWords.length}</div>
      <div>Story: {wordsForStory.length}</div>

      <FlipCard
        frontContent={reviewWords[currentWordIndex]?.en}
        onSpeakClick={() => {
          if(showDefinition) {
            handleSpeak(reviewWords[currentWordIndex]?.word)
          }
        }}
        onHelpClick={() => {
          getSentences(reviewWords[currentWordIndex]?.word)
        }}
        onImage={() => {
          getImage(reviewWords[currentWordIndex]?.word)
        }}
        onSave={(text: string) => {
          updateDefinition(reviewWords[currentWordIndex]?.word, text);
        }}
        onExplain={() => {
          getExplanation()
        }}
        onMarkForStory={() => {
          handleMarkedForStory(reviewWords[currentWordIndex]?.word)
        }}
        onLearned={() => {
          fullyLearned(reviewWords[currentWordIndex]?.word)
        }}
        canBeLearned={reviewWords[currentWordIndex]?.inter_repetition_interval_reverse > 30}
        onDelete={() => {
          deleteWord(reviewWords[currentWordIndex]?.word)
        }}
        onIgnore={() => {
          ignoreWord(reviewWords[currentWordIndex]?.word)
        }}
        backContent={reviewWords[currentWordIndex]?.word}
        isFlipped={showDefinition}
        helpLoading={sentLoading}
        lexicon={lexicon}
        flip={() => {
          if (!showDefinition) {
            handleSpeak(reviewWords[currentWordIndex]?.word)
          }
          setShowDefinition(!showDefinition)
        }}
        isRecallMode={true}
      />

      {showDefinition &&
        <div>
          {[0, 1, 2, 3, 4, 5].map(grade =>
            <button
              key={grade}
              onClick={() => handleGrade(grade)}
              className={`gradeButton grade${grade}` + (!!sentencesViewed === true && grade > 3 ? ` disabledGrade` : ``)}
            >
              {grade}
            </button>
          )}
        </div>}
        <br />
        </>
      )}
  
        {sentence1 !== "" && (
          <>
            <div>{sentence1} <FaVolumeUp
              onClick={() => {handleSpeak(sentence1)}}
              style={{ margin: "0 5px", cursor: "pointer" }}
            /></div>
                <br /> 
                <div>{sentence2} <FaVolumeUp
              onClick={() => {handleSpeak(sentence2)}}
              style={{ margin: "0 5px", cursor: "pointer" }}
            /></div>
                <br />
                <div>{sentence3} <FaVolumeUp
              onClick={() => {handleSpeak(sentence3)}}
              style={{ margin: "0 5px", cursor: "pointer" }}
            /></div>
          </>
        )}

        {explanation !== "" && (
        <div className={"explanationContainer"}>
        {explanation.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                  {line}
                  <br />
              </React.Fragment>
          ))}
        </div>
        )}
        <Footer />

    </div>
  );
};

export default ReviewScreenEn;
