import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCharacterState, selectCharacter } from "./characterSlice";

import Footer from "./components/Footer";

import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import logo from "./images/supalingo.png";
import { characterData } from "./characterData"; // import the character data
import { FaCog, FaTimes } from "react-icons/fa";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);

const HomeScreen: React.FC = () => {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [hasProfile, setHasProfile] = useState(false);
  const [uid, setUid] = useState('')


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(
    () => {
      async function getUserSettings() {
        if (!!session) {
          setLoading(true);
          const { user } = session;
          setUid(user.id)
          let { data, error } = await supabase
            .from("user_settings")
            .select(`chat_name, gender, native_language, learning_language`)
            .eq("user_id", user.id)
            .single();

          if (error) {
            console.warn(error);
            console.log("no data");
          } else if (data) {
            setHasProfile(true);
            console.log(data);
          }
          setLoading(false);
        } else {
          setUid('')
        }
      }
      getUserSettings();
    },
    [session]
  );

  const signout = () => {
    supabase.auth.signOut();
    setUid('')
  };

  const characterState = useSelector(selectCharacter);
  const dispatch = useDispatch();

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    dispatch(
      setCharacterState({ ...characterState, [e.target.id]: e.target.value })
    );
  };

  const handleCharacterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCharacter = characterData.find(
      char => char.charName === e.target.value
    );
    if (selectedCharacter) {
      dispatch(setCharacterState(selectedCharacter));
    }
  };

  return (
    <div className="AppContainer">
      <img src={logo} className="logoImage" alt="supalingo.ai" />
      <br />
      <Link className="chatlink" to="/start">
            <button
              className="blue-button"
              onClick={() => {
                // Handle your click event here
              }}
            >
              Get Started
            </button>
          </Link>
          
      <div className="form-container">
        <div className="homeHeaderContainer">
          <h2>Start a Chat</h2>
          {!!session &&
            <Link to={"/user"}>
              <FaCog className="homeSettingsIcon" />
            </Link>}
        </div>
        <br />
        <div className="form-field">
          <label htmlFor="selectCharacter">
            Select a Character to chat with:
          </label>
          <select
            id="selectCharacter"
            defaultValue={characterState.charName}
            onChange={handleCharacterChange}
          >
            {characterData.map((character, index) =>
              <option key={index} value={character.charName}>
                {character.charName}
              </option>
            )}
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="charPersonality">Character Personality:</label>
          <input
            type="text"
            id="charPersonality"
            value={characterState.charPersonality}
            onChange={handleChange}
          />
        </div>
        <div className="form-field">
          <label htmlFor="charGender">Character Gender:</label>
          <select
            id="charGender"
            value={characterState.charGender}
            onChange={handleChange}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
        <div className="form-field">
          <label htmlFor="charProfile">Character Profile:</label>
          <textarea
            id="charProfile"
            value={characterState.charProfile}
            onChange={handleChange}
          />
        </div>
        {!!session &&
          !!hasProfile &&
          <Link className="chatlink" to="/chat">
            <button
              className="blue-button"
              onClick={() => {
                // Handle your click event here
              }}
            >
              Chat with {characterState.charName}
            </button>
          </Link>}
        {!!session &&
          !hasProfile &&
          <>
          <span>First, adjust your user settings...</span>
          <br />
          <Link className="chatlink" to="/user">
            <button
              className="blue-button"
              onClick={() => {
                // Handle your click event here
              }}
            >
              Configure User Settings
            </button>
          </Link>
          </>}
        {!session &&
          <div>
            Sign in to start a chat
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa }}
              providers={["google", "apple"]}
              onlyThirdPartyProviders={true}
              redirectTo={process.env.REACT_APP_SUPA_AUTH_REDIRECT}
            />
          </div>}
      </div>
      <Footer />
    </div>
  );
};

export default HomeScreen;
