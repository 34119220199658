import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from './store';
import { createClient } from '@supabase/supabase-js'

const supaUrl =  process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";

const supabase = createClient(supaUrl, supaKey);

interface Message {
  role: string;
  content: string;
  translation: string;
  userTranslation: string;
  english: string;
  speaker: string;
  chatID: string;
  msgID: number;
  hasNewTranslation: boolean;
}

interface Option {
  english: string;
  tx: string;
}

export interface Translation {
  msgID: number,
  translation: string
  language: string
}

interface StoredMessage {
  message: string,
  translation: string,
  actor: string,
  userTranslation: string;
  timestamp: string,
  msgID: number;
}

interface ChatState {
  messages: Message[];
  options: Option[];
  isCharacterTyping: boolean;  
}

const initialState: ChatState = {
  messages: [],
  options: [],
  isCharacterTyping: false,
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      const msg: StoredMessage = {
        message: action?.payload?.content,
        translation: action?.payload?.translation || "",
        userTranslation: action?.payload?.userTranslation || "",
        actor: action?.payload?.role,
        msgID: action?.payload?.msgID,
        timestamp: new Date().toISOString(),
      }
      state.messages.push(action.payload);
    },
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = action.payload;
    },
    setOptions: (state, action: PayloadAction<Option[]>) => {
      state.options = action.payload;
    },
    setMessageTranslation: (state, action: PayloadAction<Translation>) => {
      const messageToTranslate = state.messages.find(msg => msg.msgID === action.payload.msgID);
      if (messageToTranslate) {
        messageToTranslate.translation = action.payload.translation;
        if (action.payload.language === "English") {
          messageToTranslate.english = action.payload.translation;
        }
        messageToTranslate.hasNewTranslation = true;
      }
    },
    resetHasNewTranslation: (state, action: PayloadAction<number>) => {
      const message = state.messages[action.payload];
      if (message) {
        message.hasNewTranslation = false;
      }
    },
    startCharacterTyping: (state) => {
      state.isCharacterTyping = true;
    },
    stopCharacterTyping: (state) => {
      state.isCharacterTyping = false;
    },
  },
});

export const { 
  addMessage, 
  setMessages,
  setOptions,
  startCharacterTyping, 
  stopCharacterTyping, 
  setMessageTranslation,
  resetHasNewTranslation
} = chatSlice.actions;

export const fetchResponse = (
  messages: Message[], 
  input: string,
  translated: string,
  txLang: string, 
  chatId: string, 
  userId: string,
  msgId: number,
  userLang: string, 
  charLang: string,
  userGender: string,
  charGender: string,
  userName: string,
  charName: string,
  charProfile: string,
  chatScene: string,
  ): AppThunk => async (dispatch: AppDispatch) => {

  const translation = translated === "" ? null : translated
  const transLang = txLang === "" ? null : txLang

  // save message to supabase
  const { error } = await supabase.from('messages')
  .insert({ 
    chat_id: chatId, 
    chat_user_id: userId, 
    msg_id: msgId, 
    content: input,
    translated_content: translation,
    translated_language: transLang,
    user_language: userLang, 
    character_language: charLang, 
    user_gender: userGender, 
    role: 'user',
  })
  console.log(error)

  let delay = (Math.random() * 1000) + 1500; // This generates a random delay between 500ms to 1500ms
  setTimeout(() => {
    dispatch(startCharacterTyping());
  }, delay);

  let chat = ''

  for (const msg of messages) {
    let finalMessage = msg.content
    if (msg.english !== ''){
      finalMessage = msg.english;
    }
    chat += `${msg.speaker}: ${finalMessage}\n`;
  }

  chat +=`${userName}: ${input}`

  console.log(chat)

  try {
    const response = await supabase.functions.invoke("openai-chat-continuation", {
      body: JSON.stringify({ 
        userName,
        charName,
        userGender,
        charGender,
        charLang,
        userLang,
        charProfile,
        chat,
        chatId,
        userId
      })
    });

    const resp = JSON.parse(response.data)
    console.log(resp)

    let langMessage= charLang === "English" ?  resp.nextResponse.english : resp.nextResponse.tx;
    let transMessage = charLang === "English" ?  resp.nextResponse.tx : resp.nextResponse.english;

    dispatch(addMessage({ 
      role: 'character',
      content: langMessage, 
      translation: transMessage, 
      userTranslation: '', 
      english: charLang === 'English' ? langMessage : transMessage,
      speaker: charName,
      chatID: chatId, 
      msgID: messages.length +1, 
      hasNewTranslation: false,
    }));

    window.responsiveVoice.speak(langMessage, "Thai " + charGender);
    console.log("Thai " + charGender)

    //save character message to supabase
    const { error } = await supabase.from('messages')
    .insert({ chat_id: chatId, chat_user_id: userId, msg_id: messages.length +1, content: langMessage, translated_content: transMessage, translated_language: userLang, user_language: userLang, character_language: charLang, user_gender: userGender, role: 'character'})
    console.log(error)

    dispatch(stopCharacterTyping());

    let opts = [];
    opts.push({english: resp.followupResponses.firstResponse.english, tx: resp.followupResponses.firstResponse.tx}) 
    opts.push({english: resp.followupResponses.secondResponse.english, tx: resp.followupResponses.secondResponse.tx}) 
    opts.push({english: resp.followupResponses.thirdResponse.english, tx: resp.followupResponses.thirdResponse.tx}) 
    dispatch(setOptions(opts))


  } catch(err) {
    console.log(err)
  }
};

export default chatSlice.reducer;
export type { Message };