import React, { useState, useCallback, memo } from "react";
import styles from "./FlipCard.module.css";
import { FaVolumeUp, FaCopy, FaQuestionCircle, FaSpinner, FaCheck, FaPencilAlt, FaLightbulb,FaImage, FaPlus, FaTimes, FaBookmark, FaGraduationCap, FaTrashAlt, FaEyeSlash } from "react-icons/fa";
import { LexiconResult } from "./components/Constants";

interface FlipCardProps {
  frontContent: string;
  backContent: string;
  isFlipped: boolean;
  flip: () => void;
  onSpeakClick: () => void;
  onHelpClick: () => void;
  onSave: (text: string) => void;
  onExplain: () => void;
  onImage: () => void;
  onMarkForStory: () => void;
  onLearned: () => void;
  canBeLearned: boolean;
  onDelete: () => void;
  onIgnore: () => void;
  helpLoading: boolean;
  lexicon: LexiconResult[] | null;
  isRecallMode?: boolean | null;
}

const FlipCard: React.FC<FlipCardProps> = memo(({
  frontContent,
  backContent,
  isFlipped,
  flip,
  onSpeakClick,
  onHelpClick,
  onExplain,
  onImage,
  onSave,
  onMarkForStory,
  onLearned,
  canBeLearned,
  onDelete,
  onIgnore,
  helpLoading,
  lexicon,
  isRecallMode,
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(backContent);
  const [toggleState, setToggleState] = useState(true)

  const handleSpeakClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onSpeakClick();
  }, [onSpeakClick]);

  const handleHelpClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onHelpClick();
  }, [onHelpClick]);

  const handleExplainClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onExplain();
  }, [onExplain]);

  const handleImageClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onImage();
  }, [onImage]);

  const handleMarkForStoryClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onMarkForStory();
  }, [onMarkForStory]);

  const handleLearnedClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onLearned();
  }, [onLearned]);

  const handleLearnedClickConfirm = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to mark this as learned?')) {
      onLearned();
    }
  }, [onLearned]);

  const handleDeleteClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete();
  }, [onDelete]);

  const handleIgnoreClick =  useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to ignore this?')) {
      onIgnore();
    }
  }, [onIgnore]);

  const handleSave = useCallback(() => {
    onSave(inputValue);
    setEditing(false);
  }, [onSave, inputValue]);

  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  let syls = [];
  if(!!lexicon && lexicon.length > 0) {
    syls = lexicon[0].syllables?.toneResult || [];
    console.log(syls)
  }

  return (
    <div className={styles.cardContainer} onClick={flip}>
      <div style={{ display: "flex",flexDirection: "row",flexGrow: 1, width: '100%', justifyContent: "space-between"}}>
      <FaTrashAlt onClick={handleDeleteClick} style={{ cursor: "pointer" }} />
      <FaEyeSlash onClick={handleIgnoreClick} style={{ cursor: "pointer" }} />
      <FaGraduationCap onClick={handleLearnedClickConfirm} style={{ cursor: "pointer", marginRight: 10 }} /></div>

      <div className={`${styles.card} ${isFlipped ? styles.flipped : ""}`}>
        <div className={styles.cardFront}>
          <div className={styles.cardFrontContent}>{frontContent}</div>
          {!isRecallMode && (
            <div className={styles.volumeControl} onClick={stopPropagation}>
              <FaVolumeUp onClick={handleSpeakClick} style={{ cursor: "pointer", marginRight: 10 }} />
              {helpLoading ? (
                <FaSpinner className={styles.spinnerRotate} style={{ marginRight: 10 }} />
              ) : (
                <FaQuestionCircle onClick={handleHelpClick} style={{ cursor: "pointer", marginRight: 10 }} />
              )}
              {helpLoading ? (
                <FaSpinner className={styles.spinnerRotate}  style={{ marginRight: 10 }}/>
              ) : (
                <FaLightbulb onClick={handleExplainClick} style={{ cursor: "pointer", marginRight: 10  }} />
              )}
            </div>
          )}
        </div>
        <div className={styles.cardBack} onClick={stopPropagation}>
          <div style={{ marginBottom: 10, display: "flex", flex: 1, alignItems: "center" }}>
            {!isRecallMode && (
              <FaCopy onClick={() => navigator.clipboard.writeText(frontContent)} style={{ cursor: "pointer", marginRight: 10 }} />
            )}
            {frontContent}
          </div>

          <div style={{ display: "grid", gridTemplateColumns: `repeat(${syls.length}, max-content)`, gridAutoRows: 'auto', marginBottom: 20 }}>
          {syls.flatMap((syl:string, index:any) => ([
            <div key={`top-${index}`} style={{ textAlign: 'center', gridColumn: index + 1, gridRow: 1, fontSize:12 }}>
              {syl[1].toUpperCase()}
            </div>,
            <div key={`bottom-${index}`} style={{ textAlign: 'center', gridColumn: index + 1, gridRow: 2, whiteSpace: 'nowrap', fontSize: 18 }}>
              {syl[0]}
            </div>
          ]))}
        </div>

          {isFlipped && (
            editing ? (
              <>
                <input value={inputValue} autoFocus={true} onChange={e => setInputValue(e.target.value)} />
                <FaCheck onClick={handleSave} style={{ cursor: "pointer", marginLeft: 10 }} />
              </>
            ) : (
              <div style={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isRecallMode && (
                    <FaCopy onClick={() => navigator.clipboard.writeText(backContent)} style={{ cursor: "pointer", marginRight: 10 }} />
                  )}
                  <div>{backContent}</div>
                </div>
                <div style={{ marginTop: '10px' }}>
        
      </div>
       
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaPencilAlt onClick={() => setEditing(true)} style={{ cursor: "pointer", fontSize: 16, marginLeft: 10 }} />
                </div>
              </div>
            )
          )}
           <div  style={{ marginBottom: '5px', display: "flex", flexDirection: "column", fontSize: 12 }}>
             <div style={{ cursor: 'pointer' }} onClick={() => setToggleState(prevState => (!prevState ))}>
              {toggleState ?  <FaTimes /> :<FaPlus />}
            </div> 
        {toggleState && !!lexicon && isFlipped && lexicon.map((entry, index) => (
          <div key={index} style={{ marginBottom: '5px', position: 'relative' }}>
           
           
              <>
                <strong>({entry.thai_category.toLowerCase()})</strong> {entry.eng_entry}<br />
                <i>{entry.eng_alternate}</i>
              </>
            
          </div> 
        ))}
          </div>
          <div className={styles.volumeControl} onClick={stopPropagation}>
            <FaBookmark onClick={handleMarkForStoryClick} style={{ cursor: "pointer", marginRight: 10 }} />
            
            <div style={{ flexGrow: 1 }}></div>

            <FaVolumeUp onClick={handleSpeakClick} style={{ cursor: "pointer", marginRight: 10 }} />
            {helpLoading ? (
              <FaSpinner className={styles.spinnerRotate} style={{  marginRight: 10 }} />
            ) : (
              <FaQuestionCircle onClick={handleHelpClick} style={{ cursor: "pointer", marginRight: 10 }} />
            )}
            {helpLoading ? (
              <FaSpinner className={styles.spinnerRotate} style={{  marginRight: 10  }}/>
            ) : (
              <FaLightbulb onClick={handleExplainClick} style={{ cursor: "pointer", marginRight: 10  }} />
            )}
            {isRecallMode && helpLoading ? (
              <FaSpinner className={styles.spinnerRotate} style={{ marginRight: 10 }} />
            ) : isRecallMode ? (
              <FaImage onClick={handleImageClick} style={{ cursor: "pointer", marginRight: 10 }} />
            ): <></>}
            
            <div style={{ flexGrow: 1 }}></div>

            {canBeLearned && (
              <FaGraduationCap onClick={handleLearnedClick} style={{ cursor: "pointer", marginRight: 10 }} />
            )}
            {!canBeLearned && (
              <FaGraduationCap onClick={()=>(null)} style={{ marginRight: 10, color: "lightgray" }} />
            )}
    
          </div>
        </div>
      </div>
    </div>
  );
});

export default FlipCard;
