import React, { useState, useEffect, useCallback, useRef } from "react";
import { createClient } from "@supabase/supabase-js";
import { Link } from "react-router-dom";
import { LexiconResult } from "./components/Constants";
import WordModal from "./components/WordModal";
import { userInfo } from "os";
import { Session } from "inspector";
import ReactAudioPlayer from "react-audio-player";
import Footer from "./components/Footer";

const supaUrl = process.env.REACT_APP_SUPA_URL || "";
const supaKey = process.env.REACT_APP_SUPA_PUBLIC_KEY || "";
const supabase = createClient(supaUrl, supaKey);

interface StoryScreenProps {}

const clearTextSelection = () => {
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }
};

type SavedStory = {
  id: number;
  created_at: string;
  title: string;
};

const StoryScreen: React.FC<StoryScreenProps> = () => {
  const [session, setSession] = useState<any>(null);

  const [bookmarks, setBookmarks] = useState<string[]>([]);

  const [difficultWords, setDifficultWords] = useState<string[]>([]);

  const [inputText, setInputText] = useState<string>(""); // To hold the input text.

  const [thaiText, setThaiText] = useState<string>(""); // To hold the input text.

  const [displayTokens, setDisplayTokens] = useState<string[]>([]); // To hold the processed tokens.

  const [wordTokens, setWordTokens] = useState<string[]>([]);
  const [sentenceTokens, setSentenceTokens] = useState<string[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });
  const [modalText, setModalText] = useState<string>("");
  const [modalTranslationText, setModalTranslationText] = useState<string>("");
  const [modalIsBookmarked, setModalIsBookmarked] = useState<boolean>(false);

  const [modalLex, setModalLex] = useState<LexiconResult[]>([]);

  // state for ai prompted stories
  const [proficiency, setProficiency] = useState("novice");
  const [paragraphs, setParagraphs] = useState(3);
  const [includeBookmarked, setIncludeBookmarked] = useState(false);
  const [promptText, setPromptText] = useState<string>(""); // To hold the prompt text.
  const [generating, setGenerating] = useState<boolean>(false);

  const [segmenting, setSegmenting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("GenerateStory");

  const [suggestionGenerating, setSuggestionGenerating] = useState<boolean>(
    false
  );

  const [savedStories, setSavedStories] = useState<SavedStory[]>([]);
  const [title, setTitle] = useState<string>("untitled");
  const [loadedId, setLoadedId] = useState(0);
  const [loadedTitle, setLoadedTitle] = useState("");
  const [loadedSoundUrl, setLoadedSoundUrl] = useState<string>("");
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(
    () => {
      const currentModal = modalRef.current;

      const resizeObserver = new ResizeObserver(() => {
        if (modalRef.current) {
          //
          console.log("should resize");
        }
      });

      if (modalRef.current) {
        resizeObserver.observe(modalRef.current);
      }

      return () => {
        if (currentModal) {
          resizeObserver.unobserve(currentModal);
        }
      };
    },
    [modalRef]
  );

  const handlePromptInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setPromptText(value);
  };

  const handleThaiInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setThaiText(value);
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  const handleThaiSegmentGenerate = async () => {
    setTitle("");
    setLoadedTitle("");
    setLoadedSoundUrl("");
    setLoadedId(0);
    setDisplayTokens([]);
    setSegmenting(true);
    const response2 = await supabase.functions.invoke("heroku-segmenter", {
      body: JSON.stringify({
        query: thaiText
          .replaceAll("  ", " ")
          .replaceAll("  ", " ")
          .replaceAll(" ", " S ")
          .replaceAll("\n\n", " P ")
      })
    });
    //console.log(response2.data);

    const rawWordResp = JSON.parse(response2.data);

    let wordToks: string[] = [];

    for (let i = 0; i < rawWordResp.wordTokens.length; i++) {
      if (
        rawWordResp.wordTokens[i] === "\n" ||
        rawWordResp.wordTokens[i] === "PS"
      ) {
        continue;
      } else if (rawWordResp.wordTokens[i] === "P") {
        wordToks.push("\n\n");
      } else if (rawWordResp.wordTokens[i] === "S") {
        wordToks.push(" ");
      } else {
        wordToks.push(rawWordResp.wordTokens[i]);
      }
    }

    if (wordToks[0] === "P" || wordToks[0] === "PS") {
      wordToks.shift();
    }

    for (let i = 1; i < wordToks.length; i++) {
      if (wordToks[i] === "ๆ") {
        wordToks[i - 1] += "ๆ";
        wordToks.splice(i, 1);
        i--; // Adjust index after removal
      }
    }

    setWordTokens(wordToks);
    setDisplayTokens(wordToks);

    let sentToks: string[] = [];
    for (let i = 0; i < rawWordResp.sentTokens.length; i++) {
      if (
        rawWordResp.sentTokens[i] === "\n" ||
        rawWordResp.sentTokens[i] === "PS"
      ) {
        continue;
      } else if (rawWordResp.sentTokens[i] === "P") {
        sentToks.push("\n\n");
      } else if (rawWordResp.sentTokens[i] === "S") {
        sentToks.push(" ");
      } else {
        sentToks.push(rawWordResp.sentTokens[i].replace("S", " "));
        sentToks.push(" ");
      }
    }
    if (sentToks[0] === "P" || sentToks[0] === "PS") {
      sentToks.shift();
    }
    if (sentToks[0].startsWith("PS") || sentToks[0].startsWith("P ")) {
      sentToks[0] = sentToks[0].substring(2);
    }
    setSentenceTokens(sentToks);
    console.log(sentToks);
    setDisplayTokens(wordToks);
    setSegmenting(false);
  };

  function shuffle(array: string[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  const handlePromptGenerate = async () => {
    setTitle("");
    setLoadedTitle("");
    setLoadedSoundUrl("");
    setLoadedId(0);
    setDisplayTokens([]);
    setGenerating(true);
    let bmtext = "Try to include SOME of these words where appropriate: ";
    shuffle(difficultWords);

    // If there are more than 10 words, limit it to the first 10
    const selectedWords = difficultWords.slice(0, 10);
    selectedWords.forEach((word, _) => {
      bmtext += word + ", ";
    });
    //console.log(bmtext); // this is all the difficult words.

    const inclBookmarks = includeBookmarked ? bmtext : "";

    const promptTemplate = `write me the first ${paragraphs} paragraph chapter in a story in the Thai language. The subject of the story should be: ${promptText}. You will write the first chapter only.
    Make the Thai suitable for ${proficiency} Thai language learners. ${inclBookmarks}

    Use the character P to denote paragraph separators.

    Remember Thai does not use commas or full stops.

    Only provide the Thai text formatted as requested, in your response - no other explanations or introductions.`;
    const response = await supabase.functions.invoke("openai-completions", {
      body: JSON.stringify({
        query: promptTemplate,
        model: "gpt-4-1106-preview"
      })
    });

    const val: string = response.data.choices[0].message.content;
    //get response from segmenter
    setGenerating(false);

    const response2 = await supabase.functions.invoke("heroku-segmenter", {
      body: JSON.stringify({
        query: val.replace("  ", " ").replace("  ", " ")
      })
    });
    //console.log(response2.data);

    const rawWordResp = JSON.parse(response2.data);

    let wordToks: string[] = [];

    for (let i = 0; i < rawWordResp.wordTokens.length; i++) {
      if (
        rawWordResp.wordTokens[i] === "\n" ||
        rawWordResp.wordTokens[i] === "PS"
      ) {
        continue;
      } else if (rawWordResp.wordTokens[i] === "P") {
        wordToks.push("\n\n");
      } else if (rawWordResp.wordTokens[i] === "S") {
        wordToks.push(" ");
      } else {
        wordToks.push(rawWordResp.wordTokens[i]);
      }
    }
    if (wordToks[0] === "P" || wordToks[0] === "PS") {
      wordToks.shift();
    }

    for (let i = 1; i < wordToks.length; i++) {
      if (wordToks[i] === "ๆ") {
        wordToks[i - 1] += "ๆ";
        wordToks.splice(i, 1);
        i--; // Adjust index after removal
      }
    }

    setWordTokens(wordToks);
    setDisplayTokens(wordToks);

    let sentToks: string[] = [];
    for (let i = 0; i < rawWordResp.sentTokens.length; i++) {
      if (
        rawWordResp.sentTokens[i] === "\n" ||
        rawWordResp.sentTokens[i] === "PS"
      ) {
        continue;
      } else if (rawWordResp.sentTokens[i] === "P") {
        sentToks.push("\n\n");
      } else if (rawWordResp.sentTokens[i] === "S") {
        sentToks.push(" ");
      } else {
        sentToks.push(rawWordResp.sentTokens[i].replace("S", " "));
        sentToks.push(" ");
      }
    }
    if (sentToks[0] === "P" || sentToks[0] === "PS") {
      sentToks.shift();
    }
    if (sentToks[0].startsWith("PS") || sentToks[0].startsWith("P ")) {
      sentToks[0] = sentToks[0].substring(2);
    }
    setSentenceTokens(sentToks);
  };

  const deleteStory = async (storyId: number) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this story?"
    );
    if (confirmed) {
      const { error } = await supabase
        .from("story_data")
        .delete()
        .match({ id: storyId });
      if (error) {
        console.error("Error deleting story:", error);
      } else {
        setTitle("");
        setLoadedTitle("");
        setLoadedSoundUrl("");
        setLoadedId(0);
        fetchSavedStories();
      }
    }
  };

  const saveStory = async (title: string) => {
    //function call to save story
    const { user } = session;
    const { data, error } = await supabase
      .from("story_data")
      .upsert({
        word_tokens: wordTokens,
        sentence_tokens: sentenceTokens,
        title,
        user_id: user.id
      })
      .select();

    if (error) {
      console.error("Error saving story:", error);
      return;
    }

    setTitle("");
    setLoadedTitle("");
    setLoadedSoundUrl("");
    setLoadedId(0);
    fetchSavedStories().then(() => {
      if (data && data.length > 0) {
        handleStorySelect(data[0].id);
      }
    });
  };

  const convertToSSML = async () => {
    let ssml = "<speak>";
    sentenceTokens.forEach(token => {
      if (token === "\n\n") {
        ssml += '<break time="500ms"/>';
      } else if (token === " ") {
        ssml += '<break time="50ms"/>';
      } else {
        ssml += token;
      }
    });
    ssml += "</speak>";
    console.log(JSON.stringify(ssml));
    const response = await supabase.functions.invoke("listnr-tts-story", {
      body: JSON.stringify({ ssmlString: ssml, id: loadedId, type: "story" })
    });
    console.log(response);
    handleStorySelect(loadedId);
  };

  const handleStorySelect = async (id: number) => {
    setLoadedSoundUrl("");
    console.log("loading story");
    const { user } = session;
    const { data, error } = await supabase
      .from("story_data")
      .select("*")
      .eq("id", id)
      .eq("user_id", user.id)
      .single();

    if (error) {
      console.error("Error fetching story:", error);
      return;
    }

    if (data) {
      console.log(data);
      setWordTokens(data.word_tokens);
      setSentenceTokens(data.sentence_tokens);
      setDisplayTokens(data.word_tokens);
      setLoadedId(id);
      setLoadedSoundUrl(data.audio_url);
      setLoadedTitle(data.title);
      setTitle("");
    }
  };

  const handlePromptSuggestionGenerate = async () => {
    setSuggestionGenerating(true);
    const promptTemplate = `give me a single short sentence that works as a suggested theme and topic for a very short story.  Return only the sentence, nothing else.`;
    const response = await supabase.functions.invoke("openai-completions", {
      body: JSON.stringify({
        query: promptTemplate,
        model: "gpt-4-1106-preview"
      })
    });
    //const resp = JSON.parse(response.data);
    // console.log(response.data.choices[0].message.content);
    setPromptText(response.data.choices[0].message.content);
    setSuggestionGenerating(false);
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setInputText(value);

    const tokens = value
      .replace(/<s\/>/g, " |")
      .split("|")
      .map(token => token.replace(/<P>/g, "\n\n"));
    setDisplayTokens(tokens);
  };

  const handleRepositionModal = (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();

    // Get the scroll positions.
    const scrollLeft = window.scrollX || window.pageXOffset;
    const scrollTop = window.scrollY || window.pageYOffset;

    // Adjust the modal's position based on scroll positions.
    setModalPosition({
      top: rect.top + scrollTop - 100,
      left: rect.left + scrollLeft + rect.width / 2 - 60
    });
  };

  const handleTokenClick = async (
    token: string,
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    setModalLex([]);
    setModalTranslationText(".");
    setModalText(token);

    handleRepositionModal(event);

    clearTextSelection();
    setModalIsBookmarked(bookmarks.includes(token));
    setShowModal(true);

    const response = await supabase.functions.invoke("google-translate", {
      body: JSON.stringify({
        query: token
      })
    });

    let { data: lexitron } = await supabase
      .from("lexitron_th_en")
      .select("*")
      .eq("thai_search", token);
    if (lexitron !== null) {
      //console.log(lexitron);
      //setModalLexEng = lexitron[0].
      if (lexitron.length > 0) {
        setModalLex(lexitron as LexiconResult[]);
        if (lexitron[0].syllables === null) {
          // console.log("null syllables");
          const syllsResp = await supabase.functions.invoke(
            "pythainlp-fetch-syllables",
            {
              body: JSON.stringify({
                word: token
              })
            }
          );
          const sylls = JSON.parse(syllsResp.data);
          //console.log(sylls);
        } else {
          // console.log(lexitron[0].syllables);
        }
      } else {
        setModalLex([]);
      }
    }

    const resp = JSON.parse(response.data);
    //console.log(resp.translatedText);
    setModalTranslationText(resp.translatedText);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchBookmarks = useCallback(
    async () => {
      if (session) {
        const { user } = session;
        let { data: user_word_data } = await supabase
          .from("user_word_data")
          .select("word, easiness_factor, last_review_date")
          .eq("user_id", user.id)
          .eq("bookmarked", true)
          .limit(5000);
        if (user_word_data !== null) {
          const words = user_word_data.map(obj => obj.word);
          setBookmarks(words);
          console.log(words.length);

          const todayTimestamp = new Date().getTime();
          const sevenDaysAgoTimestamp =
            todayTimestamp - 7 * 24 * 60 * 60 * 1000; // 7 days ago in milliseconds

          const difficultWords = user_word_data
            .filter(
              obj =>
                obj.easiness_factor < 1.5 &&
                new Date(obj.last_review_date).getTime() >=
                  sevenDaysAgoTimestamp
            )
            .map(obj => obj.word);

          // console.log(difficultWords);
          setDifficultWords(difficultWords);
        }
      }
    },
    [session]
  );

  const fetchSavedStories = useCallback(
    async () => {
      if (session) {
        const { user } = session;
        let { data: saved_stories } = await supabase
          .from("story_data")
          .select("id, created_at, title")
          .eq("user_id", user.id)
          .limit(5000);
        if (saved_stories !== null) {
          console.log(saved_stories);

          setSavedStories(saved_stories);
        }
      }
    },
    [session]
  );

  useEffect(
    () => {
      fetchBookmarks();
      fetchSavedStories();
    },
    [session, fetchBookmarks]
  );

  return (
    <div className="AppContainer">
      <div style={styles.outerContainer}>
        <div style={styles.sideMenu}>
          <ul>
            {savedStories
              .sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              .reduce(
                (
                  acc: { date: string; stories: typeof savedStories }[],
                  story
                ) => {
                  const storyDate = new Date(
                    story.created_at
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "2-digit"
                  });
                  const existingDate = acc.find(
                    entry => entry.date === storyDate
                  );
                  if (existingDate) {
                    existingDate.stories.push(story);
                  } else {
                    acc.push({ date: storyDate, stories: [story] });
                  }
                  return acc;
                },
                []
              )
              .map(entry =>
                <li
                  key={entry.date}
                  style={{ border: "1px solid transparent" }}
                >
                  <h3 style={{ padding: "10px", fontSize: "14px" }}>
                    {entry.date}
                  </h3>
                  {entry.stories.map(story =>
                    <div
                      key={story.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        overflow: "hidden",
                        border: "none",
                        textAlign: "left",
                        background:
                          story.title === loadedTitle ? "white" : "none",
                        fontWeight: "normal",
                        fontSize: "14px"
                      }}
                    >
                      <button
                        onClick={() => handleStorySelect(story.id)}
                        style={{
                          border: "none",
                          background: "none",
                          textAlign: "left",
                          padding: "10px",
                          fontSize: "larger",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          flex: 1
                        }}
                      >
                        {story.title.length > 30
                          ? `${story.title.substring(0, 30)}...`
                          : story.title}
                      </button>
                    </div>
                  )}
                </li>
              )}
          </ul>
        </div>
        <div style={styles.mainContent}>
          <div style={styles.tabs}>
            <div style={styles.tabList}>
              <button
                className={`tab-button ${activeTab === "GenerateStory"
                  ? "tabButtonActive"
                  : ""}`}
                onClick={() => setActiveTab("GenerateStory")}
                style={styles.tabButton}
              >
                Generate Story
              </button>
              <button
                className={`tab-button ${activeTab === "PasteText"
                  ? "tabButtonActive"
                  : ""}`}
                onClick={() => setActiveTab("PasteText")}
                style={styles.tabButton}
              >
                Paste Text
              </button>
            </div>
            <div className="tab-content" style={styles.tabContent}>
              {activeTab === "GenerateStory" &&
                <div style={styles.tabPane}>
                  <textarea
                    rows={3}
                    value={promptText}
                    onChange={handlePromptInputChange}
                    placeholder="Enter story prompt"
                    style={styles.textArea}
                  />
                  <div className="form-group" style={styles.formGroup}>
                    <label style={styles.formLabel}>
                      Thai Level
                      <select
                        value={proficiency}
                        onChange={e => setProficiency(e.target.value)}
                        style={styles.formSelect}
                      >
                        <option value="novice">Novice</option>
                        <option value="intermediate">Intermediate</option>
                        <option value="advanced">Advanced</option>
                      </select>
                    </label>
                    <label style={styles.formLabel}>
                      Paragraphs
                      <select
                        value={paragraphs}
                        onChange={e => setParagraphs(Number(e.target.value))}
                        style={styles.formSelect}
                      >
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                    </label>
                    <label style={styles.formLabel}>
                      Inject
                      <button
                        className={`blue-button ${includeBookmarked
                          ? ""
                          : "blueButtonDisabled"}`}
                        onClick={() => setIncludeBookmarked(!includeBookmarked)}
                        style={
                          includeBookmarked
                            ? styles.blueButton
                            : styles.blueButtonDisabled
                        }
                      >
                        {includeBookmarked ? "Included" : "Include"}
                      </button>
                    </label>
                  </div>
                  <button
                    className={`blue-button ${generating
                      ? "blueButtonDisabled"
                      : ""}`}
                    onClick={handlePromptGenerate}
                    disabled={generating}
                    style={
                      generating ? styles.blueButtonDisabled : styles.blueButton
                    }
                  >
                    {generating ? `Generating...` : `Generate`}
                  </button>
                </div>}
              {activeTab === "PasteText" &&
                <div style={styles.tabPane}>
                  <textarea
                    rows={3}
                    value={thaiText}
                    onChange={handleThaiInputChange}
                    placeholder="Enter Thai text"
                    style={styles.textArea}
                  />
                  <button
                    className={`blue-button ${segmenting
                      ? "blueButtonDisabled"
                      : ""}`}
                    onClick={handleThaiSegmentGenerate}
                    disabled={segmenting}
                  >
                    {segmenting ? `Segmenting...` : `Segment`}
                  </button>
                </div>}
            </div>
          </div>
          {loadedSoundUrl !== "" &&
            loadedSoundUrl !== null &&
            <ReactAudioPlayer src={loadedSoundUrl} controls />}
          {loadedTitle !== "" &&
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 25
              }}
            >
              <h2>
                {loadedTitle}
              </h2>
              <button
                style={{
                  backgroundColor: "red",
                  color: "white",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  padding: "5px",
                  borderColor: "white"
                }}
                onClick={() => deleteStory(loadedId)}
              >
                Delete
              </button>
            </div>}

          <div className="displayArea" style={styles.displayArea}>
            {displayTokens.map((token, idx) => {
              const isPunctuation = /[,.<>?()! ]/.test(token);
              const isParagraphSeparator = token === "\n\n";

              const isBookmarked = bookmarks.includes(token);

              if (isParagraphSeparator) {
                return (
                  <div key={idx} style={styles.paragraphSeparator}>
                    &nbsp;
                  </div>
                );
              }

              return (
                <span
                  key={idx}
                  className={`token ${isPunctuation ? "punctuation" : ""}`}
                  style={{
                    cursor: isPunctuation ? "default" : "pointer",
                    backgroundColor:
                      isBookmarked || token === " "
                        ? "transparent"
                        : "lightYellow",
                    ...isPunctuation ? {} : styles.token
                  }}
                  onMouseOver={e => {
                    if (!isPunctuation) {
                      e.currentTarget.style.backgroundColor = "lightgray";
                    }
                  }}
                  onMouseOut={e => {
                    if (!isPunctuation) {
                      e.currentTarget.style.backgroundColor = isBookmarked
                        ? "transparent"
                        : "lightYellow";
                    }
                  }}
                  onClick={e => {
                    if (!isPunctuation) {
                      handleTokenClick(token, e);
                    }
                  }}
                >
                  {token}
                </span>
              );
            })}
          </div>
          <br />
          <button
            style={styles.tabButton}
            onClick={() => setDisplayTokens(wordTokens)}
            disabled={suggestionGenerating}
          >
            Words
          </button>
          <button
            style={styles.tabButton}
            onClick={() => setDisplayTokens(sentenceTokens)}
            disabled={suggestionGenerating}
          >
            Sentences
          </button>
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Story Title"
              style={styles.input}
              value={title}
              onChange={e => setTitle(e.target.value)}
              disabled={suggestionGenerating}
            />
            <button
              style={styles.tabButton}
              onClick={() => saveStory(title)}
              disabled={suggestionGenerating}
            >
              SAVE
            </button>
          </div>
          <br />
          <br />
          <button
            style={styles.tabButton}
            onClick={convertToSSML}
            disabled={suggestionGenerating}
          >
            Convert to SSML
          </button>
          <br />
          <br />

          {showModal &&
            <WordModal
              text={modalText}
              translationText={modalTranslationText}
              position={modalPosition}
              onClose={handleCloseModal}
              session={session}
              fetchBookmarks={fetchBookmarks}
              isBookmarked={modalIsBookmarked}
              lexicon={modalLex}
            />}

          <Footer />
        </div>
      </div>
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  tabs: {
    border: "1px solid #ccc",
    borderRadius: "8px"
  },
  sidemenu: { width: "100px", flex: 1 },
  outerContainer: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    width: "100%"
  },
  mainContent: {
    flex: 4,
    width: "100%"
  },
  tabList: {
    listStyleType: "none",
    padding: "10px",
    display: "flex"
    // justifyContent: "center"
  },
  tabButton: {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#0000a0",
    color: "white",
    cursor: "pointer",
    marginRight: "10px"
  },
  tabButtonActive: {
    backgroundColor: "#4b4bff"
  },
  tabContent: {
    padding: "10px",
    paddingTop: "0px"
  },
  tabPane: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left"
  },
  textArea: {
    width: "100%",
    fontSize: "18px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #ccc",
    marginBottom: "10px"
  },
  formGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  formLabel: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    marginRight: "10px"
  },
  formSelect: {
    width: "100%",
    padding: "5px",
    borderRadius: "5px"
  },
  formCheckbox: {
    marginLeft: "10px"
  },
  displayArea: {
    margin: "20px",
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    fontSize: "22px",
    width: "100%", // Ensures full width
    flex: "1 1 auto" // Adjusts flex property to take the full width of the container
  }
};

export default StoryScreen;
