import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface SubscriptionState {
  subID: string;
  stripeCustomer: string;
  created: number;
  periodStart: number;
  periodEnd: number;
  priceID: string;
  interval: string;
  amount: number;
  currency: string;
  isCancelled: boolean;
  cancelledAt: number | null;
  expiresAt: number | null;
  status: string;
}

// Default user state
const initialState: SubscriptionState = {
  subID: "",
  stripeCustomer: "",
  created: 0,
  periodStart: 0,
  periodEnd: 0,
  priceID: "",
  interval: "",
  amount: 0,
  currency: "",
  isCancelled: false,
  cancelledAt: 0,
  expiresAt: 0,
  status: ""
};

export const subscriptionSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSubscription: (state, action: PayloadAction<SubscriptionState>) => {
      return action.payload;
    },
    resetSubscription: () => {
      return initialState;
    }
  }
});

export const { setSubscription, resetSubscription } = subscriptionSlice.actions;

// // Selector to get user state
export const selectSubscription = (state: RootState) => state.subscription;

export default subscriptionSlice.reducer;
