// characterSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface CharacterState {
  charName: string;
  charPersonality: string;
  charGender: string;
  charProfile: string;
}

// Default character state
const initialState: CharacterState = {
  charName: "Edward",
  charPersonality: "Disciplined and serious military officer.",
  charGender: "Male",
  charProfile:
    "Edward is a disciplined and stern military officer. He values honor, duty, and punctuality. His serious demeanor and strong sense of responsibility often make him seem aloof. He's a strategic thinker, always planning several steps ahead, and expects those around him to be equally diligent and dedicated."
};

export const characterSlice = createSlice({
  name: "character",
  initialState,
  reducers: {
    setCharacterState: (state, action: PayloadAction<CharacterState>) => {
      return action.payload;
    }
  }
});

export const { setCharacterState } = characterSlice.actions;

// Selector to get character state
export const selectCharacter = (state: RootState) => state.character;

export default characterSlice.reducer;
